import React, { useState, useEffect } from "react";
import {
  IonApp,
  IonRouterOutlet,
  IonSplitPane,
  setupIonicReact,
} from "@ionic/react";
import { isPlatform } from "@ionic/react";
import { Browser } from "@capacitor/browser";
import { Route, withRouter, useLocation } from "react-router-dom";
import "./css/style.css";
import Menu from "./components/Menu";
import Login from "./pages/Login/login-register";
import Registraion from "./pages/Registraion";
import Home from "./pages/Home";
import Verify from "./Verify";
import ViewMap from "./pages/ViewMap";
import Map from "./components/Map";
import PlaceInformation from "./pages/PlaceInformation";
import CityList from "./pages/cityList";
import ParticularPlaceInformation from "./pages/ParticularPlaceInformation";
import ViewHistoricalPlace from "./pages/ViewHistoricalPlace";
import ViewDetails from "./pages/ViewDetails";
import ViewPlacesCard from "./pages/ViewPlacesCard";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import { APIFY, HTTP_CLIENT } from "./services/Api";
import Downloads from "./pages/Downloads";
import Blogs from "./pages/Blogs";
import BlogDetails from "./pages/BlogDetails";
import ViewSpecialEvents from "./pages/ViewSpecialEvents";
import SpecialEventDetails from "./pages/SpecialEventDetails";
import { PushNotifications } from "@capacitor/push-notifications";
import { messaging } from "./index";
import { VAPID_KEY } from "./index";
import { getToken } from "firebase/messaging";
import {
  FirebaseMessaging,
  GetTokenOptions,
} from "@capacitor-firebase/messaging";
import { Capacitor } from "@capacitor/core";
import { isBrowser } from "react-device-detect";
import { getLangLabel } from "./utility/languageTranslator";
setupIonicReact();

const App = ({ history }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Assuming mobile width is 768px or less
  const [fcm, setFcm] = useState("");
  const location = useLocation();
  const [langCode, setlangCode] = useState("en");

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    history.push("/home");
    const handleContextMenu = (e) => {
      e.preventDefault();
    };

    const handleKeyDown = (e) => {
      if (
        (e.ctrlKey && (e.key === "p" || e.key === "s")) ||
        e.key === "PrintScreen" ||
        e.metaKey
      ) {
        e.preventDefault();
      }
    };

    const handleKeyUp = (e) => {
      if (e.key === "PrintScreen") {
        e.preventDefault();
      }
    };

    document.addEventListener("contextmenu", handleContextMenu);
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);

    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const notificationActions = async (langCode) => {
    if (isMobile) {
      const addListeners = async () => {
        //token
        await PushNotifications.addListener("registration", (token) => {
          console.log("Registration token: ", token.value);
          setFcm(token.value);
        });

        //reg error
        await PushNotifications.addListener("registrationError", (err) => {
          console.error("Registration error: ", err.error);
        });
        // notification received

        await PushNotifications.addListener(
          "pushNotificationReceived",
          (notification) => {
            console.log("Push notification received", notification);
          }
        );

        await PushNotifications.addListener(
          "pushNotificationActionPerformed",
          (notification) => {
            console.log(
              "Push notification action performed",
              notification?.notification?.data
            );

            const data = notification?.notification?.data;

            console.log("notificationdata", data);

            if (data?.redirectPath === "/blogs" && data?.pathId === "") {
              history.push("/blogs");
            } else if (data?.redirectPath === "/blogs" && data?.pathId) {
              history.push(`/blogdetails/${data?.pathId}`); // Corrected path construction
            } else {
              history.push("/home");
            }
          }
        );
      };

      const registerNotifications = async () => {
        let permStatus = await PushNotifications.checkPermissions();

        if (permStatus.receive === "prompt") {
          permStatus = await PushNotifications.requestPermissions();
        }

        if (permStatus.receive !== "granted") {
          throw new Error("User denied permissions!");
        }

        if (permStatus.receive === "granted") {
          try {
            await PushNotifications.register();
            addListeners();
          } catch (error) {
            alert(error);
          }
        }
      };
      registerNotifications();
    } else {
      let permission = await Notification.requestPermission();

      if (permission === "granted") {
        const token = await getToken(messaging, {
          vapidKey: VAPID_KEY,
        });

        setFcm(token);
        console.log("Token generated:", token);
      } else if (permission === "denied") {
        alert(getLangLabel(langCode)?.notificationDenied);
      }
    }
  };

  useEffect(() => {
    const languageCode = localStorage.getItem("languageCode");
    setlangCode(languageCode || "en");
    let deviceToken = localStorage.getItem("mobileToken");
    console.log("oldtoken", deviceToken);

    if (deviceToken === null || deviceToken === undefined) {
      deviceToken = ""; // Corrected this line
    }

    if (fcm !== deviceToken) {
      localStorage.setItem("mobileToken", fcm);
      console.log("fcm", fcm);
    }

    notificationActions(languageCode);

    const data = { newToken: fcm, oldToken: deviceToken };
    const sendToken = async () => {
      try {
        console.log("dataobj", data);
        const res = await HTTP_CLIENT(APIFY("/deviceTokens"), "POST", data);
        console.log("success", res);
      } catch (err) {
        console.log("error", err);
      }
    };

    if (fcm !== deviceToken) {
      sendToken();
    }
  }, [isMobile, isBrowser, fcm]);

  // const notificationActions = async (langCode) => {
  //   if (Capacitor.isNativePlatform()) {
  //     const addListeners = async () => {
  //       // Token
  //       await FirebaseMessaging.addListener('registration', (token) => {
  //         console.log('Registration token: ', token.value);
  //         setFcm(token.value);
  //       });

  //       // Registration error
  //       await FirebaseMessaging.addListener('registrationError', (err) => {
  //         console.error('Registration error: ', err.error);
  //       });

  //       // Notification received
  //       await FirebaseMessaging.addListener('pushNotificationReceived', (notification) => {
  //         console.log('Push notification received', notification);
  //       });

  //       // Notification action performed
  //       await FirebaseMessaging.addListener('pushNotificationActionPerformed', (notification) => {
  //         console.log('Push notification action performed', notification?.notification?.data);

  //         const data = notification?.notification?.data;

  //         console.log('notification data', data);

  //         if (data?.redirectPath === '/blogs' && data?.pathId === '') {
  //           window.location.href = '/blogs';
  //         } else if (data?.redirectPath === '/blogs' && data?.pathId) {
  //           window.location.href = `/blogdetails/${data?.pathId}`; // Corrected path construction
  //         } else {
  //           window.location.href = '/home';
  //         }
  //       });
  //     };

  //     const registerNotifications = async () => {
  //       let permStatus = await FirebaseMessaging.checkPermissions();

  //       if (permStatus.receive === 'prompt') {
  //         permStatus = await FirebaseMessaging.requestPermissions();
  //       }

  //       if (permStatus.receive !== 'granted') {
  //         throw new Error('User denied permissions!');
  //       }

  //       if (permStatus.receive === 'granted') {
  //         try {
  //           await FirebaseMessaging.register();
  //           addListeners();
  //         } catch (error) {
  //           alert(error);
  //         }
  //       }
  //     };
  //     registerNotifications();
  //   } else {
  //     let permission = await Notification.requestPermission();

  //     if (permission === 'granted') {
  //       const registerServiceWorker = async () => {
  //         const options = {
  //           vapidKey: VAPID_KEY,
  //         };

  //         if (Capacitor.getPlatform() === "web") {
  //           try {
  //             options.serviceWorkerRegistration = await navigator.serviceWorker.register("firebase-messaging-sw.js");
  //           } catch (error) {
  //             console.error('Error registering service worker:', error);
  //           }
  //         }

  //       };
  //       registerServiceWorker();
  //            const token = await getToken(messaging, {
  //                vapidKey: VAPID_KEY,
  //            });

  //       setFcm(token);
  //       console.log('Token generated:', token);
  //     } else if (permission === 'denied') {
  //       alert(`Notification permission denied in ${langCode}`);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   const languageCode = localStorage.getItem('languageCode');
  //   setlangCode(languageCode || 'en');
  //   let deviceToken = localStorage.getItem('mobileToken');
  //   console.log('old token', deviceToken);

  //   if (deviceToken === null || deviceToken === undefined) {
  //     deviceToken = ''; // Corrected this line
  //   }

  //   if (fcm !== deviceToken) {
  //     localStorage.setItem('mobileToken', fcm);
  //     console.log('fcm', fcm);
  //   }

  //   notificationActions(languageCode || 'en');

  //   const data = { newToken: fcm, oldToken: deviceToken };
  //   const sendToken = async () => {
  //     try {
  //       console.log('data object', data);
  //       // Replace HTTP_CLIENT and APIFY with your actual HTTP client and API URL
  //       const res = await HTTP_CLIENT(APIFY('/deviceTokens'), 'POST', data);
  //       console.log('success', res);
  //     } catch (err) {
  //       console.log('error', err);
  //     }
  //   };

  //   if (fcm !== deviceToken) {
  //     sendToken();
  //   }

  // }, [fcm]);

  return (
    <IonApp>
      <IonSplitPane when="false" contentId="main">
        <Menu />
        <IonRouterOutlet id="main" animated={false}>
          <Route path="/" exact={true}>
            <Home />
          </Route>
          <Route path="/home" exact={true}>
            <Home />
          </Route>
          <Route path="/registration" exact={true}>
            <Registraion />
          </Route>
          <Route path="/verify-email" exact={true}>
            <Verify />
          </Route>
          <Route path="/viewmap/:name" exact={true}>
            <ViewMap />
          </Route>
          <Route path="/citylist" exact={true}>
            <CityList />
          </Route>
          <Route path="/placeinformation" exact={true}>
            <PlaceInformation />
          </Route>
          <Route path="/particularplaceinformation" exact={true}>
            <ParticularPlaceInformation />
          </Route>
          <Route path="/viewplacescard" exact={true}>
            <ViewPlacesCard />
          </Route>
          <Route path="/viewspecialevents/:_id" exact={true}>
            <ViewSpecialEvents />
          </Route>
          <Route path="/specialeventdetails/:placeId" exact={true}>
            <SpecialEventDetails />
          </Route>
          <Route path="/viewDetails" exact={true}>
            <ViewDetails />
          </Route>
          <Route path="/viewhistoricalplace/:name" exact={true}>
            <ViewHistoricalPlace />
          </Route>
          <Route path="/map" exact={true}>
            <Map />
          </Route>
          <Route path="/downloads" exact={true}>
            <Downloads />
          </Route>
          <Route path="/blogs" exact={true}>
            <Blogs />
          </Route>
          <Route path="/blogdetails/:pathId" exact>
            <BlogDetails />
          </Route>
          <Route path="/blogdetails/:_id" exact>
            <BlogDetails />
          </Route>
        </IonRouterOutlet>
      </IonSplitPane>
    </IonApp>
  );
};

export default withRouter(App);
