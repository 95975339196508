import React, { useEffect, useState } from "react";
import {
  IonPage,
  IonHeader,
  IonContent,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonLoading,
  IonModal,
} from "@ionic/react";
import { useLocation, withRouter } from "react-router";
import { Navigation, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "react-multi-carousel/lib/styles.css";
import {
  getDataByCollection,
  getLatestAppVersion,
  getStateList,
} from "../../modalconfig";
import AreaSelector from "../../components/AreaSelector";
import { APIFY, HTTP_CLIENT } from "../../services/Api";

import { Network } from "@capacitor/network";
import { Geolocation } from "@capacitor/geolocation";
import { FirebaseAnalytics } from "@capacitor-firebase/analytics";
import { isMobile } from "react-device-detect";
import { SpeechRecognition as CapacitorSpeechRecognition } from "@capacitor-community/speech-recognition";
import AnimatedStartScreen from "../../components/AnimatedStartScreen";
import DesktopHeader from "../../components/DesktopHeader";
import Footer from "../../components/Footer";
import maplogo from "../../images/Vector Smart Object.png";
import card1 from "../../images/tiger.jpg";
import card2 from "../../images/lotusTemple.jpg";
import card3 from "../../images/temp1.png";
import txtimg from "../../images/Group 3.png";
import { isPlatform } from "@ionic/react";
import remove from "../../images/remove.png";
import { getCityInLang, getLangLabel } from "../../utility/languageTranslator";
import SearchInput from "../../components/SearchInput";
import { App } from "@capacitor/app";
import UpdateAvailableAlert from "../../components/UpdateAvailableAlert";

const Home = ({ history }) => {
  const [isAnimated, setIsAnimated] = useState(false);
  const location = useLocation();
  const [districtName, setDistrictName] = useState("");
  const [stateName, setStateName] = useState("");
  const [searchlocation, setSearchLocation] = useState(() => {
    const storedValue = localStorage.getItem("searchlocation");
    return storedValue ? storedValue === "true" : false;
  });
  const [isOpen, setIsOpen] = useState(true);
  const [netavailable, setnetavailable] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);
  const [filters, setFilters] = useState([]);
  const [postorImg, setPostorImg] = useState([]);
  const [showLoading, setShowLoading] = useState(true);
  const [specialEvents, setSpecialEvents] = useState([]);
  const [langCode, setlangCode] = useState("en");
  const [cities, setCities] = useState([]);
  const [netChecked, setNetChecked] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [currentVersion, setCurrentVersion] = useState(null);
  const [latestAppVersion, setLatestAppVersion] = useState(null);
  const [updateAvailable, setUpdateAvailable] = useState(false);
  const [show, setShow] = useState(false);

  useEffect(() => {
    const getStatus = async () => {
      const status = await sessionStorage.getItem("splashLogin");
      if (!status) {
        setShow(true);
        await sessionStorage.setItem("splashLogin", "true");
        const timer = setTimeout(() => {
          setIsAnimated(true);
        }, 5000);
        return () => clearTimeout(timer);
      } else {
        setShow(false);
        setIsAnimated(false);
      }
    };

    getStatus();

    // Cleanup function to reset the splash screen status when the component unmounts
    return () => {
      localStorage.setItem("splashLogin", "false");
    };
  }, [location.key]);
  function compareVersions() {
    const parseVersion = (version) => version.split(".").map(Number);

    const current = parseVersion(currentVersion);
    const latest = parseVersion(latestAppVersion);

    for (let i = 0; i < latest.length; i++) {
      if (current[i] < latest[i]) {
        return -1; // Current version is older
      } else if (current[i] > latest[i]) {
        return 1; // Current version is newer
      }
    }
    return 0; // Versions are the same
  }
  useEffect(() => {
    const fetchAppVersions = async () => {
      try {
        const latestVersionRes = await getLatestAppVersion();
        const { version } = await App.getInfo();

        console.log("testing", latestVersionRes);
        let latestVersion = null;
        if (isPlatform("android")) latestVersion = latestVersionRes.android;
        if (isPlatform("ios")) latestVersion = latestVersionRes.ios;

        latestVersion = latestVersionRes.android;
        setCurrentVersion(version);
        setLatestAppVersion(latestVersion);
      } catch (error) {
        console.error("Failed to get app version: ", error);
      }
    };
    if (isPlatform("android") || isPlatform("ios")) fetchAppVersions();
  }, []);
  useEffect(() => {
    if (currentVersion && latestAppVersion) {
      const compare = compareVersions();
      if (compare === -1) {
        setUpdateAvailable(true);
      }
    }
  }, [currentVersion, latestAppVersion]);

  const getCorrectCityName = (city) => {
    const cities = JSON.parse(localStorage.getItem("cities"));
    const res = cities?.filter(
      (obj) => obj?.city === city || obj?.cityKey === city
    );
    setCities(res[0]?.city);
    return res[0]?.city;
  };
  const checkIsDesktop = () => {
    setIsDesktop(window.innerWidth > 1024);
  };
  const apiKey = "AIzaSyA8UPOL-NXIwjEzHwfXRcVvTICWQ64AQ6Q";

  //

  const setAnalyticsScreen = async () => {
    try {
      await FirebaseAnalytics.setCurrentScreen({
        screenName: `Home Page`,
        screenClass: "Home Page",
      });
      await FirebaseAnalytics.logEvent({
        name: "screen_view",
        params: {
          screen_name: `Home Page`,
          screen_class: "Home Page",
        },
      });
      console.log("Screen view event logged");
    } catch (error) {
      console.error("Error logging screen view event:", error);
    }
  };

  useEffect(() => {
    setAnalyticsScreen();
  }, []);

  const logCurrentNetworkStatus = async () => {
    try {
      const status = await Network.getStatus();
      console.log("Network status:", status.connected);
      setnetavailable(status.connected || false);
      setNetChecked(true);
      localStorage.setItem("Networkstatus", status.connected);
    } catch (error) {
      console.error("Error checking network status:", error);
    }
  };

  useEffect(() => {
    logCurrentNetworkStatus();

    const networkStatusChangeListener = Network.addListener(
      "networkStatusChange",
      (status) => {
        console.log("Network status changed:", status.connected);
        setnetavailable(status.connected || false);
        setNetChecked(true);
        localStorage.setItem("Networkstatus", status.connected);
      }
    );

    return () => {
      networkStatusChangeListener.remove();
    };
  }, []);

  //
  useEffect(() => {
    const requestMicrophonePermissions = async () => {
      try {
        CapacitorSpeechRecognition.requestPermissions();
      } catch (error) {
        console.error("Failed to request microphone permissions:", error);
      }
    };

    if (isMobile) {
      requestMicrophonePermissions();
    }
  }, []);

  //
  const findLocation = () => {
    setSearchLocation(true);
  };

  const explorePlace = () => {
    if (stateName !== "" && districtName !== "") {
      localStorage.setItem(
        "placeDetails",
        JSON.stringify({ state: stateName, city: districtName })
      );
      history.push(`/placeinformation`);
    }
  };

  ///setting expiration time for the district value from API
  const setWithExpiration = (key, value, ttl) => {
    const now = new Date().getTime();
    const item = {
      value: value,
      expiry: now + ttl,
    };
    localStorage.setItem(key, JSON.stringify(item));
    console.log("setting item", item);
  };
  ///
  ///get with expiration
  const getWithExpiration = (key) => {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    console.log("getting item parsed", item);

    const now = new Date().getTime();

    if (now > item.expiry) {
      // If the item is expired, delete the item from storage and return null
      localStorage.removeItem(key);
      return null;
    }
    console.log("getting item parsed2", item.value);
    return item.value;
  };

  useEffect(() => {
    const printCurrentPosition = async () => {
      let getdistname = await getWithExpiration("districtget");
      let getstname = await getWithExpiration("stateget");
      console.log("distName===123", getdistname);
      console.log("stateName===123", getstname);
      if (getdistname !== null && getstname !== null) {
        const cityName = getCorrectCityName(getdistname);
        setDistrictName(cityName);
        setStateName(getstname);
      }
      if (getdistname === null) {
        try {
          // Request permission to access geolocation
          if (isMobile) {
            let permission = await Geolocation.checkPermissions();
            if (permission.location === "granted") {
              // Permission granted, proceed with geolocation retrieval
              const coordinates = await Geolocation.getCurrentPosition();
              const { latitude, longitude } = coordinates.coords;
              setLatitude(latitude);
              setLongitude(longitude);
              const response = await fetch(
                `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`
              );
              const data = await response.json();
              console.log("test1", data.plus_code.compound_code);
              if (data.status === "OK") {
                let district = "";
                let state = "";
                const addressComponents = data.results[0].address_components;

                for (const component of addressComponents) {
                  if (component.types.includes("administrative_area_level_3")) {
                    district = component.long_name;
                  }
                  if (component.types.includes("administrative_area_level_1")) {
                    state = component.long_name;
                  }
                  if (district && state) break;
                }
                const cityName = getCorrectCityName(district);
                setDistrictName(cityName);

                setStateName(state);
                setWithExpiration("districtget", district, 900000);
                setWithExpiration("stateget", state, 900000);
              } else {
                console.error("Failed to retrieve address information");
              }
            } else if (permission.location !== "granted") {
              alert(getLangLabel(langCode)?.geoPermission);
              permission = await Geolocation.requestPermissions();
              if (permission.location !== "granted") {
                return null;
              } else if (permission.location === "granted") {
                printCurrentPosition();
              }
            }
          } else {
            if (navigator.geolocation) {
              // check if permission is granted
              navigator.permissions
                .query({ name: "geolocation" })
                .then((permissionStatus) => {
                  console.log("permissionStatus", permissionStatus);
                  if (
                    permissionStatus.state === "prompt" ||
                    permissionStatus.state === "granted"
                  ) {
                    // permission already granted, get the current user's location
                    navigator.geolocation.getCurrentPosition(
                      async (position) => {
                        // save the geolocation coordinates in two variables
                        const { latitude, longitude } = position.coords;
                        setLatitude(latitude);
                        setLongitude(longitude);
                        const response = await fetch(
                          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`
                        );
                        const data = await response.json();
                        if (data.status === "OK") {
                          let district = "";
                          let state = "";
                          const addressComponents =
                            data.results[0].address_components;

                          for (const component of addressComponents) {
                            if (
                              component.types.includes(
                                "administrative_area_level_3"
                              )
                            ) {
                              district = component.long_name;
                            }
                            if (
                              component.types.includes(
                                "administrative_area_level_1"
                              )
                            ) {
                              state = component.long_name;
                            }
                            if (district && state) break;
                          }
                          const cityName = getCorrectCityName(district);
                          setDistrictName(cityName);
                          window.location.reload();
                          setStateName(state);
                          setWithExpiration("districtget", district, 900000);
                          setWithExpiration("stateget", state, 900000);
                        } else {
                          console.error(
                            "Failed to retrieve address information"
                          );
                        }
                      },

                      // if there was an error getting the user's location
                      (error) => {
                        console.error("Error getting user location:", error);
                      }
                    );
                  } else if (permissionStatus.state !== "granted") {
                    alert(getLangLabel(langCode)?.geoPermission);

                    if (permissionStatus.state !== "granted") {
                      return null;
                    } else if (permissionStatus.state === "granted") {
                      printCurrentPosition();
                      setSearchLocation(true);
                    }
                  }
                });
            } else {
              console.error("Geolocation is not supported by this browser.");
            }
          }
        } catch (error) {
          console.error("Error getting current position:", error);
        } finally {
          setSearchLocation(true);
        }
      } else {
        console.log("district name already present");
      }
    };

    if (searchlocation === true && stateName == "" && districtName == "") {
      localStorage.setItem("searchlocation", searchlocation);
      printCurrentPosition();
    }
  }, [searchlocation, cities]);
  const getCities = async () => {
    const res1 = await getStateList({});
    localStorage.setItem("cities", JSON.stringify(res1?.city || []));
  };
  useEffect(() => {
    setIsOpen(true);
    getSpecialEvents();
    checkIsDesktop();
    getCities();
    window.addEventListener("resize", checkIsDesktop);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", checkIsDesktop);
    };
  }, []);

  const getSpecialEvents = async () => {
    try {
      const res = await HTTP_CLIENT(APIFY(`/special-events`), "GET");
      setSpecialEvents(res.data);
    } catch (err) {
      console.log("error", err);
    }
  };

  const closeModal = () => {
    setIsOpen(false);
    sessionStorage.setItem("isOpen", false);
  };

  useEffect(() => {
    const showModal =
      sessionStorage.getItem("isOpen") === "true" ||
      sessionStorage.getItem("isOpen") === null;
    setIsOpen(showModal);
    const languageCode = localStorage.getItem("languageCode");
    if (languageCode) {
      setlangCode(languageCode || "en");
    } else {
      localStorage.setItem("languageCode", "en");
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (netavailable) {
        setShowLoading(true);
        let fil = await getDataByCollection("filters");
        localStorage.setItem("filters", JSON.stringify(fil));
        let postor_img = await getDataByCollection("homeimages");
        setFilters(fil);
        setDataLoaded(true);
        if (postor_img?.length > 0) {
          setPostorImg(postor_img[0]?.data);
        }
        setShowLoading(false);
      } else {
        setDataLoaded(true);
        setShowLoading(false);
      }
    };
    if (netChecked) fetchData();
  }, [netChecked, netavailable, langCode]);

  const postor_carocel = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  return (
    <IonPage>
      {show && <AnimatedStartScreen isAnimated={isAnimated} />}

      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={"Please wait..."}
      />
      <IonHeader
        translucent={isDesktop ? true : false}
        className={`ion-no-border ${isDesktop ? "translucent-header" : ""}`}
      >
        <IonToolbar className="t-bar">
          <div className="top-nav">
            <div className="nav_inner">
              <IonButtons slot="end">
                <IonMenuButton className="color-white" />
              </IonButtons>

              <h4 className="title-txt">{getLangLabel(langCode)?.home}</h4>
              <IonButtons slot="end" style={{ visibility: "hidden" }}>
                <IonMenuButton className="color-white" />
              </IonButtons>
            </div>
          </div>
        </IonToolbar>
        <DesktopHeader />
      </IonHeader>
      <IonContent
        fullscreen={isDesktop ? true : false}
        className={isDesktop ? "overlapped" : ""}
      >
        {netavailable && (
          <>
            <div className="home-sec">
              <div className="bannerArea">
                {postorImg?.length === 0 && (
                  <div className="banner-carousel">
                    <img
                      alt=" "
                      src="./assets/icon/goldentemple.jpg"
                      className="carousel_img"
                      width="100%"
                    />
                  </div>
                )}

                {postorImg?.length > 0 && (
                  <Swiper
                    className="carousel-container carousel-list banner-carousel"
                    slidesPerView={1}
                    loop={false}
                  >
                    {postorImg?.map((img, index) => {
                      return (
                        <SwiperSlide
                          className="carousel-item-padding-40-px"
                          key={index}
                        >
                          <img
                            key={`sl-${index}`}
                            alt=" "
                            src={img}
                            width="100%"
                            className="carousel_img"
                          />
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                )}
              </div>
              <div className="goldenBg">
                <SearchInput history={history} langCode={langCode} />
                <div className="bg-grey">
                  {filters && (
                    <>
                      <div className="locationChoose">
                        {!searchlocation ? (
                          <div
                            className="locationChooseLabel"
                            onClick={findLocation}
                          >
                            {getLangLabel(langCode)?.location}
                          </div>
                        ) : (
                          districtName && (
                            <div
                              className="locationChooseLabel"
                              onClick={explorePlace}
                            >
                              {getLangLabel(langCode)?.locationL1}
                              {langCode === "en"
                                ? districtName
                                : getCityInLang(districtName, langCode)}
                              <p>{getLangLabel(langCode)?.locationL2}</p>
                            </div>
                          )
                        )}
                      </div>
                      <div className="home-carousel2">
                        {/* <h4 className="inner-title ion-text-center">
                          {getLangLabel(langCode)?.discover}
                        </h4> */}

                        <div className="filterSlider">
                          <Swiper
                            modules={[Navigation, A11y]}
                            className="carousel-container carousel-list"
                            navigation={isDesktop ? true : false}
                            spaceBetween={10}
                            slidesPerView={3}
                            speed={500}
                            slidesOffsetAfter={10}
                            slidesOffsetBefore={0}
                            resistanceRatio={0}
                            freeMode={true}
                            breakpoints={{
                              575: {
                                slidesPerView: 3,
                              },
                              768: {
                                slidesPerView: 5,
                              },
                              1025: {
                                slidesPerView: 6,
                              },
                              1400: {
                                slidesPerView: 7,
                              },
                            }}
                            loop={false}
                          >
                            {filters?.map((item, index) => {
                              return (
                                <SwiperSlide
                                  className="carousel-item-padding-40-px"
                                  key={index}
                                >
                                  <div
                                    className="caros-card1 dis_card1"
                                    style={{ backgroundColor: item.bg }}
                                    onClick={() => {
                                      if (item?.subFilter?.length > 0) {
                                        localStorage.setItem(
                                          "filterDetails",
                                          JSON.stringify(item || [])
                                        );
                                        history.push(`/viewDetails`);
                                      } else {
                                        let data = {
                                          filterType: "type",
                                          filtername: item.label,
                                          state: "",
                                          city: "",
                                        };
                                        localStorage.setItem(
                                          "filterDetails",
                                          JSON.stringify(data)
                                        );
                                        history.push(`/viewplacescard`);
                                      }
                                    }}
                                  >
                                    <img alt="filter img" src={item.icon} />
                                    <h5>
                                      {item?.label_lang?.[langCode]
                                        ? item?.label_lang?.[langCode]
                                        : item?.label}
                                    </h5>
                                  </div>
                                </SwiperSlide>
                              );
                            })}
                          </Swiper>
                        </div>
                      </div>
                    </>
                  )}

                  <div className="tagLine forMbl">
                    <p>
                      {getLangLabel(langCode)?.exploreL1} &nbsp;
                      <strong>{getLangLabel(langCode)?.exploreL2}</strong>
                    </p>
                    <p>{getLangLabel(langCode)?.exploreL3}</p>
                  </div>

                  <div className="home-carousel3">
                    <div className="tagLine forDesktop">
                      <p>
                        {getLangLabel(langCode)?.exploreL1}

                        <strong> {getLangLabel(langCode)?.exploreL2}</strong>
                      </p>
                      <p>{getLangLabel(langCode)?.exploreL3}</p>
                    </div>
                    <div>
                      {specialEvents?.map((item, index) => (
                        <div
                          key={index}
                          className={`swiper-slide ${
                            index === 1 ? "swiper-slide-active" : ""
                          }`}
                        >
                          <div
                            className="carousel-item-padding-40-px"
                            onClick={() =>
                              history.push(`/viewspecialevents/${item?._id}`)
                            }
                          >
                            <div className="caros-card1 dis_card1">
                              <img alt=" " src={item.icon} />
                              <div className="card-caption">
                                <h5 style={{ background: "none" }}>
                                  {item?.label_lang?.[langCode]
                                    ? item?.label_lang?.[langCode]
                                    : item?.label}
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="map-container">
                    <h5
                      style={{
                        color: "black",
                        marginBottom: "0px",
                        fontWeight: "bolder",
                        fontSize: "15px",
                        marginTop: "0px",
                        padding: "20px 5px 20px 5px",
                      }}
                    >
                      {getLangLabel(langCode)?.map}
                    </h5>
                    <AreaSelector />
                  </div>
                </div>
              </div>
            </div>

            {isDesktop && (isOpen === true || isOpen === null) && (
              <IonModal isOpen={isOpen} onDidDismiss={() => setIsOpen(false)}>
                <IonContent className="pageContent">
                  <div className="AppLandingPage">
                    <div className="spacer"></div>
                    <img
                      src={maplogo}
                      className="img-fluid mapLogo"
                      alt="Map Logo"
                    />
                    <div className="remove-container" onClick={closeModal}>
                      <img alt=" " src={remove} width={40} />
                    </div>
                    <div className="spacer"></div>
                    <div className="tiles">
                      <div
                        className="logincard"
                        style={{
                          backgroundImage: `url(${card1})`,
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          backgroundPositionX: "-30px",
                        }}
                      ></div>
                      <div className="logincard" style={{ marginTop: "60px" }}>
                        <img alt=" " src={card3} />
                      </div>
                      <div className="logincard">
                        <img alt=" " src={card2} />
                      </div>
                    </div>
                    <div className="spacer"></div>
                    <img
                      src={txtimg}
                      className="img-fluid textLogo"
                      alt="Text Img"
                    />
                    <div className="spacer"></div>
                  </div>
                </IonContent>
              </IonModal>
            )}
          </>
        )}
        {updateAvailable && (
          <UpdateAvailableAlert showAlert={updateAvailable} />
        )}
        {!netavailable && dataLoaded && (
          <>
            <div className="container" style={{ height: "100%" }}>
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <img
                  alt="net"
                  src="./assets/icon/disconnected.png"
                  className="img-fluid"
                  style={{ height: "250px" }}
                />
                <button
                  style={{
                    marginTop: "20px",
                    backgroundColor: "#FA4939",
                    color: "white",
                    padding: "20px 40px",
                    borderRadius: "5px",
                    border: "none",
                    cursor: "pointer",
                  }}
                  onClick={() => history.push("/downloads")}
                >
                  {getLangLabel(langCode)?.goToDownload}
                </button>
              </div>
            </div>
          </>
        )}
        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default withRouter(Home);
