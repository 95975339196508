import { getFilteredData } from "../modalconfig";
import { useIonViewDidEnter } from "@ionic/react";
import { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";

const MyMapComponent = (url, img) => {
  const [adds, setAdds] = useState([]);
  useIonViewDidEnter(async () => {
    let details = JSON.parse(localStorage.getItem("placeDetails"));
    let fil = await getFilteredData("ads", "state", details?.state);
    setAdds(fil);
  });

  const responsiveimg = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  useEffect(() => {}, [adds]);

  return (
    <div style={adds?.length > 0 ? { height: "75px" } : { display: "none" }}>
      {adds?.length > 1 ? (
        <Carousel
          swipeable={true}
          draggable={false}
          showDots={false}
          responsive={responsiveimg}
          ssr={true} // means to render carousel on server-side.
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={3000}
          keyBoardControl={true}
          customTransition="all 1.2s ease 0s"
          transitionDuration={1000}
          containerClass="carousel-container carousel-list"
          removeArrowOnDeviceType={["tablet", "mobile"]}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
          slidesToSlide="1"
          arrows={false}
        >
          {adds?.map((item) => {
            return (
              <div style={{ height: "74px" }}>
                <a href={item?.image} target="_blank" rel="noreferrer">
                  <img
                    alt=" "
                    src={item?.image}
                    style={{ width: "100%", height: "100%" }}
                  />
                </a>
              </div>
            );
          })}
        </Carousel>
      ) : (
        <div style={{ height: "74px" }}>
          <a
            href={adds?.length > 0 ? adds[0]?.image : ""}
            target="_blank"
            rel="noreferrer"
          >
            <img
              alt=" "
              src={adds?.length > 0 ? adds[0]?.image : ""}
              style={{ width: "100%", height: "100%" }}
            />
          </a>
        </div>
      )}
    </div>
  );
};

export default MyMapComponent;
