import React from "react";
import {
  IonPage,
  IonHeader,
  IonContent,
  IonToolbar,
  IonSearchbar,
} from "@ionic/react";
import { useParams, withRouter } from "react-router";
// import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import Carousel from "react-multi-carousel";
import State from '../../components/mapComponents/StateChart'
import "react-multi-carousel/lib/styles.css";

const ViewMap = ({ history }) => {
  let { name } = useParams();
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  React.useEffect(() => {



  }, [name]);
  return (
    <IonPage>
      <IonHeader  style={{background: "#fff"}}>
        <IonToolbar className="t-bar">
          <div className="top-nav">
            <div className="nav_inner">
              <ArrowBackOutlinedIcon
                className="color-white"
                onClick={() => history.push('/home')}
              />
              <h4 className="title-txt">Home</h4>
              <div className="d-flex">
                {/* <NotificationsOutlinedIcon className="color-white" />
              <IonButtons slot="end">
                <IonMenuButton className="color-white" />
              </IonButtons> */}
              </div>
            </div>
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="map-sec">
          <div className="top-banner">
            <img alt=" " src="./assets/icon/StateBg.jpg" />
            <div className="banr-box">
              <h5 className="sub-title">Welcome to</h5>
              <h4 className="title-txt">{name}</h4>
            </div>
            <IonSearchbar
              className="s-bar"
              placeholder="Search by place"
            ></IonSearchbar>
          </div>
          <div className="bg-grey">
            <div className="padding-box">
              <div
                className="card pos-box"
              >
                <h4 className="inner-title">
                  Tap Anywhere you want to explore
                </h4>
                <State selectedState={name} onClick={(city) => {
                  let data = JSON.parse(localStorage.getItem('details'));
                  history.push(`/placeinformation`);
                  let cityData = {
                    ...data,
                    city: city
                  }
                  window.localStorage.setItem('details', JSON.stringify(cityData))

                }} />
                <div className="zoom-btn">
                  <img alt=" " src="./assets/icon/ZoomIn.png" />
                  <img alt=" " src="./assets/icon/ZoomOut.png" />
                </div>
              </div>
            </div>
            <div className="map-carousel">
              <div className="padding-box">
                <h4 className="inner-title">Suggested Place</h4>
                <Carousel
                  swipeable={true}
                  draggable={false}
                  showDots={false}
                  responsive={responsive}
                  ssr={true} // means to render carousel on server-side.
                  infinite={true}
                  autoPlay={true}
                  autoPlaySpeed={3000}
                  keyBoardControl={true}
                  customTransition="all 1.2s ease 0s"
                  transitionDuration={1000}
                  containerClass="carousel-container carousel-list"
                  removeArrowOnDeviceType={["tablet", "mobile"]}
                  dotListClass="custom-dot-list-style"
                  itemClass="carousel-item-padding-40-px"
                  slidesToSlide="1"
                  arrows={false}
                >
                  <div className="caros-card ">
                    <img alt=" " src="./assets/icon/Districtplace.jpg" />
                    <h5>Dakhineswar</h5>
                  </div>
                  <div className="caros-card ">
                    <img alt=" " src="./assets/icon/StateBg.jpg" />
                    <h5>Belur Math</h5>
                  </div>
                  {/* <div className="caros-card ">
                    <img alt=" " src="./assets/icon/goldenplace.jpg" />
                    <h5>Lorem</h5>
                  </div> */}
                </Carousel>
              </div>
            </div>
          </div>
        </div>

      </IonContent>
    </IonPage>
  );
};

export default withRouter(ViewMap);
