export const getCityInLang = (city, langCode) => {
  const cities = JSON.parse(localStorage.getItem("cities"));
  const res = cities?.filter(
    (obj) => obj?.city === city || obj?.cityKey === city
  );
  return res[0]?.city_lang?.[langCode];
};
export const getLangState = () => {
  return {
    "Andhra Pradesh": "आंध्र प्रदेश",
    "Arunachal Pradesh": "अरुणाचल प्रदेश",
    Assam: "असम",
    Bihar: "बिहार",
    "Chandigarh (UT)": "चंडीगढ़ (यूटी)",
    Chhattisgarh: "छत्तीसगढ",
    "Dadra and Nagar Haveli (UT)": "दादरा और नगर हवेली (यूटी)",
    "Daman and Diu (UT)": "दमन और दीव (यूटी)",
    Delhi: "दिल्ली",
    Goa: "गोवा",
    Gujarat: "गुजरात",
    Haryana: "हरियाणा",
    "Himachal Pradesh": "हिमाचल प्रदेश",
    Jharkhand: "झारखंड",
    "Jammu and Kashmir": "जम्मू और कश्मीर",
    Karnataka: "कर्नाटक",
    Kerala: "केरल",
    "Madhya Pradesh": "मध्य प्रदेश",
    Maharashtra: "महाराष्ट्र",
    Manipur: "मणिपुर",
    Meghalaya: "मेघालय",
    Mizoram: "मिजोरम",
    Nagaland: "नगालैंड",
    Odisha: "ओडिशा",
    "Puducherry (UT)": "पुडुचेरी (यूटी)",
    Punjab: "पंजाब",
    Rajasthan: "राजस्थान",
    Sikkim: "सिक्किम",
    "Tamil Nadu": "तमिलनाडु",
    Telangana: "तेलंगाना",
    Tripura: "त्रिपुरा",
    "Uttar Pradesh": "उत्तर प्रदेश",
    Uttarakhand: "उत्तराखंड",
    "West Bengal": "पश्चिम बंगाल",
    "Andaman & Nicobar Island": "अंडमान और निकोबार द्वीप",
    Lakshadweep: "लक्षद्वीप",
    Ladakh: "लद्दाख",
  };
};

export const getLangLabel = (lang) => {
  if (lang === "hi")
    return {
      search: "अपना गंतव्य खोजें",
      location: "स्थान चुनने के लिए क्लिक करें",
      locationL1: "आप यहां पर है: ",
      locationL2: "अपने आस-पास के स्थानों का पता लगाने के लिए क्लिक करें",
      discover: "अभी खोजें",
      exploreL1: "ऐसे खोजें",
      exploreL2: "भारत को",
      exploreL3: "जैसे पहले कभी नहीं देखा",
      map: "आप जहां भी एक्सप्लोर करना चाहते हैं, वहां टैप करें",
      footerL1: "© सभी अधिकार मनोहर भरूका द्वारा सुरक्षित - चलो भारत देखें",
      footerL2: "गोपनीयता नीति",
      footerL3: "नियम और शर्तें",
      footerL4: "उपयोगकर्ता पुस्तिका",
      option1: "ब्लॉग",
      option2: "संपर्क करें",
      option3: "ऐप साझा करें",
      menuL1: "बुकमार्क",
      menuL2: "ब्लॉग",
      menuL3: "गोपनीयता नीति",
      menuL4: "नियम और शर्तें",
      menuL5: "उपयोगकर्ता पुस्तिका",
      menuL6: "संपर्क करें",
      menuL7: "ऐप साझा करें",
      menuFooter: "© सभी अधिकार मनोहर भरूका द्वारा सुरक्षित - चलो भारत देखें",
      loginBtnLabel: "आएँ शुरू करें",
      loginContact: "संपर्क करें :",
      loginFooter: "© सभी अधिकार मनोहर भरूका द्वारा सुरक्षित - चलो भारत देखें",
      alreadyVisited: "क्या आप पहले ही इस जगह का दौरा कर चुके हैं ?",
      review: "समीक्षा",
      reviewLabel: "समीक्षा",
      notReviews: "अभी तक कोई समीक्षा नहीं",
      cancelBtn: "रद्द करना",
      enterReview: "अपनी समीक्षा दर्ज करें",
      reviewHere: "यहां समीक्षा करें",
      addPhotos: "तस्वीरें जोडो",
      submit: "जमा करना",
      reviewFooter: `भारत देखो ऐसी टिप्पणियाँ पोस्ट नहीं करेगा जिनमें अपमानजनक, अश्लील, अपमानजनक, धमकी देने वाली या परेशान करने वाली भाषा, किसी भी प्रकार के व्यक्तिगत हमले या विशिष्ट व्यक्तियों या समूहों को लक्षित करने वाले आक्रामक शब्द शामिल हों।`,
      nearPlaces: "निकटवर्ती स्थान",
      noNearPlaces: "आस-पास कोई स्थान उपलब्ध नहीं है",
      welcomeTo: "आपका स्वागत है",
      home: "होम",
      goToDownload: "बुकमार्क पर जाएँ",
      downloads: "बुकमार्क",
      notDownloads: "अभी तक कोई बुकमार्क नहीं",
      alreadyDownloaded: "डेटा पहले ही डाउनलोड हो चुका है।",
      dataDownloaded: "डेटा सफलतापूर्वक डाउनलोड हो गया।",
      pleaseWait: "कृपया प्रतीक्षा करें...",
      notificationDenied: "आपने अधिसूचना के लिए मना कर दिया।",
      geoPermission:
        "जियोलोकेशन तक पहुँचने की अनुमति अस्वीकृत. कृपया इस सुविधा का उपयोग करने के लिए स्थान सेवाओं को सक्षम करें।",
      dataDeleted: "डेटा सफलतापूर्वक हटा दिया गया है।",
      imagesError:
        "छवियों को संसाधित करते समय एक त्रुटि उत्पन्न हुई. कृपया पुन: प्रयास करें।",
      reviewSaved: "समीक्षा सहेजी गई.",
    };
  else {
    return {
      search: "Search your destination",
      location: "Click to choose location",
      locationL1: "You are at: ",
      locationL2: "Click to explore places near you",
      discover: "DISCOVER NOW",
      exploreL1: "EXPLORE",
      exploreL2: "INDIA",
      exploreL3: "LIKE NEVER BEFORE!",
      map: "Tap Anywhere you want to explore",
      footerL1:
        "© All rights reserved by Manohar Bharuka - Chalo Bharat Dekhen",
      footerL2: "Privacy Policy",
      footerL3: "Terms and Conditions",
      footerL4: "User Manual",
      option1: "Blogs",
      option2: "Contact",
      option3: "Share the app",
      menuL1: "Bookmarks",
      menuL2: "Blogs",
      menuL3: "Privacy Policy",
      menuL4: "Terms and Conditions",
      menuL5: "User Manual",
      menuL6: "Contact Us",
      menuL7: "Share the app",
      menuFooter:
        "© All rights reserved by Manohar Bharuka - Chalo Bharat Dekhen",
      loginBtnLabel: "Let's Get Started",
      loginContact: "Contact Us :",
      loginFooter:
        "© All rights reserved by Manohar Bharuka - Chalo Bharat Dekhen",
      alreadyVisited: "Already visited this place ?",
      review: "Review",
      reviewLabel: "Reviews",
      notReviews: "No reviews yet",
      cancelBtn: "Cancel",
      enterReview: "Enter Your Review",
      reviewHere: "Review here",
      addPhotos: "Add Photos",
      submit: "Submit",
      reviewFooter: `Chalo Bharat Dekhen will not post comments that contain abusive,
                      vulgar, offensive, threatening or harassing language,
                      personal attacks of any kind, or offensive terms that target
                      specific individuals or groups.`,
      nearPlaces: "Nearby Places",
      noNearPlaces: "No Nearby Places Available",
      welcomeTo: "Welcome to",
      home: "Home",
      goToDownload: "Go to bookmarks",
      downloads: "Bookmarks",
      notDownloads: "No Bookmarks yet",
      alreadyDownloaded: "Data already downloaded.",
      dataDownloaded: "Data downloaded successfully",
      pleaseWait: "Please wait...",
      notificationDenied: "You denied for the notification",
      geoPermission:
        "Permission to access geolocation denied. Please enable location services to use this feature.",
      dataDeleted: "Data deleted successfully.",
      imagesError:
        "An error occurred while processing the images. Please try again.",
      reviewSaved: "Review saved.",
    };
  }
};
