import React, { useEffect, useState } from "react";
import {
  IonPage,
  IonHeader,
  IonContent,
  IonToolbar,
  IonList,
  IonModal,
} from "@ionic/react";
import Paper from "@mui/material/Paper";
import { useLocation, withRouter } from "react-router";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { getStateList } from "../../modalconfig";
import DesktopHeader from "../../components/DesktopHeader";
import remove from "../../images/remove.png";
import logo from "../../images/logo.png";
import { getLangState } from "../../utility/languageTranslator";

const City = ({ history }) => {
  const [cityList, setCityList] = useState([]);
  const [stateName, setStateName] = useState("");
  const [isDesktop, setIsDesktop] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const location = useLocation();
  const [langCode, setLangCode] = useState("en");
  const checkIsDesktop = () => {
    setIsDesktop(window?.innerWidth > 1024 || false);
  };

  useEffect(() => {
    if (
      location.pathname === "/placeinformation" ||
      location.pathname === "/particularplaceinformation"
    ) {
      setIsOpen(false);
      return null;
    }


    if (
      location.pathname === "/cityList" ||
      (location.pathname === "/home" && isOpen||!isDesktop)
    ) {
      setIsOpen(true);
      const fetchData = async () => {
        const languageCode = localStorage.getItem("languageCode");
        setLangCode(languageCode || "en");
        const details = JSON.parse(localStorage.getItem("placeDetails"));
        const state =
          languageCode === "en"
            ? details?.state
            : getLangState()?.[details?.state];
        setStateName(state);
        checkIsDesktop();
        const res = await getStateList({ state: details?.state });
        setCityList(res?.city || []);
      };
      fetchData();

      window.addEventListener("resize", checkIsDesktop);
      return () => {
        window.removeEventListener("resize", checkIsDesktop);
      };
    }
  }, [location.key]);

  const handleGoBack = () => {
    history.goBack();
  };

  const gotoHome = () => {
    setStateName("");
    setCityList([]);
    history.push("/home");
  };

  const renderContent = () => (
    <IonContent className="">
      <div className="">
        <div className="flex-head">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "0 20px 0 20px",
            }}
          >
            <h4 className="inner-title">{stateName || ""}</h4>
            <img
              src={remove}
              width={40}
              height={40}
              onClick={() => {
                setIsOpen(false);
                setStateName("");
                setCityList([]);
                history.push("/home");
              }}
              alt=""
            />
          </div>
        </div>
        <div className="columnContainer">
          <div className="bg-grey">
            <IonList>
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{ fontWeight: "700", fontSize: "15px" }}
                      >
                        Id
                      </TableCell>
                      <TableCell
                        style={{ fontWeight: "700", fontSize: "15px" }}
                      >
                        District
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {cityList?.map((item, index) => (
                      <TableRow
                        key={item?._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {index + 1}
                        </TableCell>
                        <TableCell
                          onClick={async () => {
                            let details = await JSON.parse(
                              localStorage.getItem("placeDetails")
                            );
                            let updated_details = {
                              ...details,
                              city: item?.city || "",
                            };
                            await localStorage.setItem(
                              "placeDetails",
                              JSON.stringify(updated_details)
                            );
                            setIsOpen(false);
                            setStateName("");
                            setCityList([]);
                            history.push(`/placeinformation`);
                          }}
                        >
                          {item?.city_lang?.[langCode]
                            ? item?.city_lang?.[langCode]
                            : item?.city}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </IonList>
          </div>
        </div>
      </div>
    </IonContent>
  );

  return (
    <IonPage>
      <IonHeader style={{ background: "#fff" }}>
        <IonToolbar className="t-bar">
          <div className="top-nav">
            <div className="nav_inner">
              <ArrowBackOutlinedIcon
                className="color-white"
                onClick={handleGoBack}
              />
              <h3
                style={{
                  textAlign: "center",
                  fontWeight: "700",
                  color: "white",
                }}
              >
                {stateName || ""}
              </h3>
              <img
                onClick={gotoHome}
                src={logo}
                width={50}
                height={40}
                alt=""
              />
            </div>
          </div>
        </IonToolbar>
        <DesktopHeader />
      </IonHeader>
      {isDesktop ? (
        <IonModal isOpen={isOpen}>{renderContent()}</IonModal>
      ) : (
        renderContent()
      )}
    </IonPage>
  );
};

export default withRouter(City);
