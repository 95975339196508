/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { InAppBrowser } from "@awesome-cordova-plugins/in-app-browser";
import { getLangLabel } from "../utility/languageTranslator";


const Footer = ({ history }) => {
    const [langCode, setlangCode] = useState("en");

    var target = "_self";
    var iaboptions = {
        location: "no", //Or 'no'
        hideurlbar: "yes", //Or 'no'
        hidenavigationbuttons: "yes",
        fullscreen: "yes",
        toolbarcolor: "#000000",
        closebuttoncaption: "exit",
        clearcache: "yes",
    };

    useEffect(() => {
        const languageCode = localStorage.getItem("languageCode");
        setlangCode(languageCode||"en");
      }, [langCode]);
    return (
        <>
            <div className="footer">
                <div className="footer-inner">
                   
                  {getLangLabel(langCode)?.footerL1}
                    <div className="footerLinks">
                        <ul>
                            <li>
                                <span onClick={async () => {
                                    let url = "https://chalobharatdekhen.com/privacy-policy";
                                    InAppBrowser.create(url, target, iaboptions);
                                }}>   {getLangLabel(langCode)?.footerL2}</span>
                            </li>
                            <li>
                                <span onClick={async () => {
                                    let url = "https://chalobharatdekhen.com/terms-and-conditions";
                                    InAppBrowser.create(url, target, iaboptions);
                                }}>   {getLangLabel(langCode)?.footerL3}</span>
                            </li>
                            <li>
                                <span onClick={async () => {
                                    let url = "https://chalobharatdekhen.com/user-manual";
                                    InAppBrowser.create(url, target, iaboptions);
                                }}>
                                       {getLangLabel(langCode)?.footerL4}
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};

export default withRouter(Footer);
