/* eslint-disable react-hooks/exhaustive-deps */
import {
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonMenu,
  IonMenuToggle,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import "./Menu.css";
import { InAppBrowser } from "@awesome-cordova-plugins/in-app-browser";
import { Share } from "@capacitor/share";
import { getLangLabel } from "../utility/languageTranslator";

const Menu = ({ history }) => {
  const [showLoading, setShowLoading] = useState(false);
  const [langCode, setlangCode] = useState("en");
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("languageCode") || "en"
  );
  const Languageslist = [
    // { code: "", name: "Select Language" },
    { code: "en", name: "English" },
    { code: "hi", name: "Hindi" },
  ];

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language.code);
    localStorage.setItem("languageCode", language.code);
    window.location.reload();
  };

  //
  var target = "_system";
  var iaboptions = {
    location: "no", //Or 'no'
    hideurlbar: "yes", //Or 'no'
    hidenavigationbuttons: "yes",
    fullscreen: "yes",
    toolbarcolor: "#000000",
    closebuttoncaption: "exit",
    clearcache: "yes",
  };

  useEffect(() => {
    const languageCode = localStorage.getItem("languageCode");
    setlangCode(languageCode || "en");
  }, [langCode]);

  const phoneNumber = "+916289991595";
  const openWhatsApp = () => {
    const whatsappUrl = `https://wa.me/${phoneNumber}`;
    window.open(whatsappUrl, "_blank");
  };

  const shareApp = async () => {
    await Share?.share({
      text: `Explore India with our travel app! Discover expert tips, curated itineraries, and must-see destinations for an unforgettable journey. Download now and start your adventure!
      1. Play Store- https://play.google.com/store/apps/details?id=chalo.bharatdekhen.app
      2. Apple Store- https://apps.apple.com/in/app/bharat-dekhen/id6478861610`,
    });
  };

  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <div className="logo_ctr">
          <img alt=" " src="./assets/icon/logo.png" className="logo_width" />
        </div>
        <IonList id="inbox-list">
          {
            <IonMenuToggle key={""} autoHide={false}>
              <IonItem
                className={""}
                routerDirection="none"
                lines="none"
                detail={false}
                onClick={() => history.push("/downloads")}
              >
                <div className="sidebar_img_crop">
                  <img alt=" " src={"./assets/icon/star.png"} height={30} />
                </div>
                <IonLabel style={{ marginLeft: "10px" }}>
                  {getLangLabel(langCode)?.menuL1}
                </IonLabel>
              </IonItem>
            </IonMenuToggle>
          }

          {/* {
            <IonItem
              className={""}
              routerDirection="none"
              lines="none"
              detail={false}
            >
              <img
                alt=" "
                src={"./assets/icon/languages.png"}
                height={25}
                className="image_of_sidebar"
              />
              <IonLabel>
                <select
                  value={selectedLanguage}
                  onChange={(e) =>
                    handleLanguageChange(
                      Languageslist.find((lang) => lang.code === e.target.value)
                    )
                  }
                  onClick={(e) => e.stopPropagation()}
                  style={{ color: "black", border: "1px solid #cccccc", padding: "8px", width: "122px", fontSize: "12px", borderRadius: "5px", backgroundColor: "rgb(255 255 255 / 75%)" }}
                >
                  {Languageslist.map((language) => (
                    <option key={language.code} value={language.code}>
                      {language.name}
                    </option>
                  ))}
                </select>
              </IonLabel>
            </IonItem>
          }  */}
          {/* {
            <IonMenuToggle key={""} autoHide={false}>
              <IonItem
                className={""}
                routerDirection="none"
                lines="none"
                detail={false}
                onClick={() => history.push("/blogs")}
              >
                <div className="sidebar_img_crop">
                  <img alt=" " src={"./assets/icon/blog.png"} height={30} />
                </div>
                <IonLabel style={{ marginLeft: "10px" }}>
                  {getLangLabel(langCode)?.menuL2}
                </IonLabel>
              </IonItem>
            </IonMenuToggle>
          } */}

          {
            <IonMenuToggle key={""} autoHide={false}>
              <IonItem
                className={""}
                routerDirection="none"
                lines="none"
                detail={false}
                onClick={async () => {
                  let url = "https://chalobharatdekhen.com/privacy-policy";
                  InAppBrowser.create(url, target, iaboptions);
                }}
              >
                <div className="sidebar_img_crop">
                  <img
                    alt=""
                    src={"./assets/icon/privacy-policy.png"}
                    height={30}
                  />
                </div>
                <IonLabel style={{ marginLeft: "10px" }}>
                  {getLangLabel(langCode)?.menuL3}
                </IonLabel>
              </IonItem>
            </IonMenuToggle>
          }
          {
            <IonMenuToggle key={""} autoHide={false}>
              <IonItem
                className={""}
                routerDirection="none"
                lines="none"
                detail={false}
                onClick={async () => {
                  let url = "https://chalobharatdekhen.com/terms-and-conditions";
                  InAppBrowser.create(url, target, iaboptions);
                }}
              >
                <div className="sidebar_img_crop">
                  <img
                    alt=" "
                    src={"./assets/icon/terms-and-conditions.png"}
                    height={30}
                  />
                </div>
                <IonLabel style={{ marginLeft: "10px" }}>
                  {getLangLabel(langCode)?.menuL4}
                </IonLabel>
              </IonItem>
            </IonMenuToggle>
          }
          {
            <IonMenuToggle key={""} autoHide={false}>
              <IonItem
                className={""}
                routerDirection="none"
                lines="none"
                detail={false}
                onClick={async () => {
                  let url = "https://chalobharatdekhen.com/user-manual";
                  InAppBrowser.create(url, target, iaboptions);
                }}
              >
                <div className="sidebar_img_crop">
                  <img
                    alt=" "
                    src={"./assets/icon/user-guide.png"}
                    height={30}
                  />
                </div>
                <IonLabel style={{ marginLeft: "10px" }}>
                  {getLangLabel(langCode)?.menuL5}
                </IonLabel>
              </IonItem>
            </IonMenuToggle>
          }
          {
            <IonMenuToggle key={""} autoHide={false}>
              <IonItem
                className={""}
                routerDirection="none"
                lines="none"
                detail={false}
                onClick={openWhatsApp}
              >
                <div className="sidebar_img_crop">
                  <img alt=" " src={"./assets/icon/whatsapp.png"} height={30} />
                </div>
                <IonLabel style={{ marginLeft: "10px" }}>
                  {getLangLabel(langCode)?.menuL6}
                </IonLabel>
              </IonItem>
            </IonMenuToggle>
          }
          {
            <IonMenuToggle key={""} autoHide={false}>
              <IonItem
                className={""}
                routerDirection="none"
                lines="none"
                detail={false}
                onClick={shareApp}
              >
                <div className="sidebar_img_crop">
                  <img alt=" " src={"./assets/icon/share.png"} height={30} />
                </div>
                <IonLabel style={{ marginLeft: "10px" }}>
                  {getLangLabel(langCode)?.menuL7}
                </IonLabel>
              </IonItem>
            </IonMenuToggle>
          }
        </IonList>
        <IonLoading
          cssClass="loadingCss"
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
        />
        <IonItem>
          <IonLabel style={{ textAlign: "center", whiteSpace: "normal" }}>
            {getLangLabel(langCode)?.menuFooter}
            <br />
            support@chalobharatdekhen.com
          </IonLabel>
        </IonItem>
      </IonContent>
    </IonMenu>
  );
};

export default withRouter(Menu);
