import React, { useState } from "react";
import {
  IonPage,
  IonContent,
  IonInput,
  IonButton,
} from "@ionic/react";
import { withRouter } from "react-router";
import FormControl from "@mui/material/FormControl";
// import { Link } from "react-router-dom";
import { APIFY, HTTP_CLIENT } from "../../services/Api";
import { useHistory, Link } from "react-router-dom";
import { DESTROY_DATA } from "../../helpers/Base";
const Registration = ({ history }) => {
  const duplicatehistory = useHistory();
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [cpassword, setCPassword] = useState("");

  const validatePassword = () => {
    let isValid = true;
    if (password !== "" && cpassword !== "") {
      if (password !== cpassword) {
        isValid = false;
        // setError('Passwords does not match')
      }
    }
    return isValid;
  };

  async function register() {
    
    if (validatePassword()) {

      let params = {
        "name": name,
        "email": email,
        "password": password,
        "phone": phone,
        "role": ["user"]
      }

      DESTROY_DATA()

      HTTP_CLIENT(APIFY("/users/register"), "POST", params)
      .then((data) => {

        const user = data.data;

        setName('');
        setPhone('');
        alert("Register succesfully");
        history.push('/')


      })
      .catch((err) => {
      });

    

    } else {
      alert("password did not match");
    }
    setEmail("");
    setPassword("");
    setCPassword("");

    // }
    // else{
    // }
  }

  return (
    <IonPage>
      <IonContent className="login-bg">
          <div className="padding-box">
            {/* <div className="top-logo">
            <img alt=" "src="./assets/logo.png" />
            </div> */}
            <div className="login-sec">
              <h4>Sign up</h4>
              <FormControl variant="standard" className="form-box">
              <IonInput
                  id="input-with-icon-textfield"
                  placeholder="Name"
                  className="text-input"
                  onIonChange={(e) => setName(e.target.value)}
                  value={name}
                ></IonInput>
                 <IonInput
                  id="input-with-icon-textfield"
                  placeholder="Phone"
                  type="number"
                  max={5}
                  maxLength={5}
                  inputProps = {{maxLength:6}}
                  className="text-input"
                  onIonChange={(e) => {
                    setPhone(e.target.value)
                    setPassword(e.target.value)
                    setCPassword(e.target.value)
                  }}
                  value={phone}
                ></IonInput>

                <IonInput
                  id="input-with-icon-textfield"
                  placeholder="Email"
                  className="text-input"
                  onIonChange={(e) => setEmail(e.target.value)}
                  value={email}
                ></IonInput>

                {/* <IonInput
                  id="input-with-icon-textfield"
                  placeholder="Password"
                  className="text-input"
                  onIonChange={(e) => setPassword(e.target.value)}
                  type="password"
                  value={password}
                ></IonInput>
                <IonInput
                  id="input-with-icon-textfield"
                  placeholder="Confirm Password"
                  className="text-input"
                  onIonChange={(e) => setCPassword(e.target.value)}
                  type="password"
                  value={cpassword}
                ></IonInput> */}

                <IonButton className="white-btn" onClick={register}>
                  Register
                </IonButton>
              </FormControl>

              {/* <ion-text  color="white" onClick={()=>history.push('./registration')}> */}
              <h4 color="white">
                {" "}
                Allready have accont? <Link to="/">Login</Link>
              </h4>
              {/* </ion-text> */}
            </div>
          </div>
      </IonContent>
    </IonPage>
  );
};

export default withRouter(Registration);
