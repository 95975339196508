
export function SET_PAGE_TITLE(title) {
  const pageTitle = `Chalo Bhart Dekhen App`
  return (document.title = `${title} - ${pageTitle}`)
}

export function SET_DATA(key, value) {
  localStorage.setItem(key, JSON.stringify(value))
  return true
}

export function GET_DATA(key) {
  var value = localStorage.getItem(key)
  var jsonValue = null
  try {
    jsonValue = JSON.parse(value)
  } catch (err) {
    jsonValue = null
  }
  return jsonValue
}

export function REMOVE_DATA(key) {
  localStorage.removeItem(key)
  return true
}

export function DESTROY_DATA() {
  let keys = ['userDetails','access_token', 'userEmail', 'IsLogged', 'loginSkiped']
  keys.forEach((key) => {
    REMOVE_DATA(key)
  })
  return true
}

export function GET_QUERY(key) {
  const searchParams = new URLSearchParams(document.location.search)
  return searchParams.get(key)
}