import React from "react";
import PropTypes from "prop-types";
import {
  IonAlert,
  IonButton,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { Browser } from "@capacitor/browser";
import { isPlatform } from "@ionic/react";

const Alert = ({ showAlert }) => {
  const handleUpdate = async () => {
    const url = isPlatform("android")
      ? "https://play.google.com/store/apps/details?id=chalo.bharatdekhen.app"
      : "https://apps.apple.com/in/app/bharat-dekhen/id6478861610";
    if (isPlatform("android") || isPlatform("ios")) await Browser.open({ url });
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Ionic React App</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonAlert
          isOpen={showAlert}
          header={"Update Available"}
          subHeader={"A new update is available!"}
          message={
            "Please update the app to get the latest features and improvements."
          }
          buttons={[
            {
              text: "OK",
              handler: handleUpdate,
            },
          ]}
        />
      </IonContent>
    </IonPage>
  );
};

Alert.propTypes = {
  showAlert: PropTypes.bool.isRequired,
};

export default Alert;
