import React, { useState, useEffect } from "react";
import {
    IonPage,
    IonHeader,
    IonContent,
    IonToolbar,
    IonButtons,
    IonMenuButton,
    useIonViewDidEnter
} from "@ionic/react";
import { withRouter } from "react-router";
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useParams } from "react-router";
import { getFilteredData } from "../../modalconfig";


const ViewHistoricalPlace = ({ history }) => {
    let { name } = useParams();
    const [data, setDetails] = useState([]);
    const [historical_data, setHistoricalDetails] = useState([]);

    useIonViewDidEnter(async () => {
        let details = await JSON.parse(localStorage.getItem('details'))
        let res = await getFilteredData("places", "city", details.city);
        setDetails(res);


    })



    useEffect(() => {
        if (data.length > 0) {
            let historical = data.filter((item) => item.type === "historical");
            setHistoricalDetails(historical);
        }
    }, [data]);

    const imgSlider = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    return (
        <IonPage>
            <IonHeader  style={{background: "#fff"}}> 
                <IonToolbar className="t-bar">
                    <div className="top-nav">
                        <div className="nav_inner">
                            <ArrowBackOutlinedIcon className="color-white" onClick={() => history.goBacK()} />
                            {/* <h4 className="title-txt"></h4> */}
                            <div className="d-flex">
                                {/* <NotificationsOutlinedIcon className="color-white" /> */}
                                <IonButtons slot="end">
                                    <IonMenuButton className="color-white" />
                                </IonButtons>
                            </div>
                        </div>
                    </div>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <div className="bg-grey">
                    <div className="img-card">
                        <div className="padding-box">
                            <div className="flex-head">
                                <h4 className="inner-title"> Historical Places of {name}</h4>
                                <span className="fil-icon">
                                    <img alt=" " src="./assets/icon/filter.png" />
                                </span>
                            </div>
                            {historical_data.map((item) => {
                                return <div className="caros-card">
                                    <Carousel className="img-caros"
                                        swipeable={true}
                                        draggable={false}
                                        showDots={false}
                                        responsive={imgSlider}
                                        ssr={true} // means to render carousel on server-side.
                                        infinite={false}
                                        autoPlay={false}
                                        // autoPlaySpeed={5000}
                                        keyBoardControl={true}
                                        customTransition="all 1.2s ease 0s"
                                        transitionDuration={1000}
                                        containerClass="carousel-container carousel-list"
                                        dotListClass="custom-dot-list-style"
                                        itemClass="carousel-item-padding-40-px"
                                        slidesToSlide="1"
                                        arrows={true}
                                    >
                                        <img alt=" " src={item.img} />
                                    </Carousel>
                                    <h5>{item.name}</h5>
                                    <div className="wrap-head">
                                        <span>
                                            <LocationOnIcon />
                                            <h6>{`${item.city}, ${item.state}`}</h6>
                                        </span>
                                        {/* <FavoriteBorderIcon /> */}
                                    </div>
                                    <div className="desc-box">
                                        <p>{item.details}</p>
                                    </div>
                                </div>
                            })}

                            {/* <div className="caros-card " >
                                <img alt=" "src="./assets/icon/StateBg.jpg" />
                                <h5>Place name lorem ipsum</h5>
                                <div className="flex-head">
                                    <span><LocationOnIcon /><h6>State name, District name</h6></span>
                                    <FavoriteBorderIcon />
                                </div>
                            </div>
                            <div className="caros-card " >
                                <img alt=" "src="./assets/icon/Districtbg.jpg" />
                                <h5>Place name lorem ipsum</h5>
                                <div className="flex-head">
                                    <span><LocationOnIcon /><h6>State name, District name</h6></span>
                                    <FavoriteBorderIcon />
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default withRouter(ViewHistoricalPlace);