import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  FacebookAuthProvider,
} from "firebase/auth";
import {
  getFirestore,
  query,
  getDocs,
  collection,
  addDoc,
  doc,
  getDoc,
} from "firebase/firestore";
import { APIFY, HTTP_CLIENT } from "./services/Api";
import * as draftToHtml from "draftjs-to-html";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import { DESTROY_DATA } from "./helpers/Base";

const firebaseConfig = {
  apiKey: "AIzaSyCuwRQflf0HFg57P3W6G1_Wd_G0xDvBcr8",
  authDomain: "travel-app-35c54.firebaseapp.com",
  projectId: "travel-app-35c54",
  storageBucket: "travel-app-35c54.appspot.com",
  messagingSenderId: "583669625898",
  appId: "1:583669625898:web:f8db701005b393db9ebf53",
  measurementId: "G-C01QVJ5TBK",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();
const signInWithFacebook = async (history) => {
  try {
    const res = await signInWithPopup(auth, facebookProvider);
    const user = res.user;
    console.log(user);
    DESTROY_DATA();
    loginwithuid(user, history);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};
const signInWithGoogle = async (history) => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    DESTROY_DATA();
    loginwithuid(user, history);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const loginwithuid = async (user, history) => {
  let params = { uid: user.uid };

  HTTP_CLIENT(APIFY("/users/loginwithUid"), "POST", params)
    .then((res) => {
      const user = res.data;

      if (res && user && user.email) {
        localStorage.setItem("IsLogged", true);
        localStorage.setItem("userEmail", user.email);
        localStorage.setItem("access_token", user.token);

        history.push("/home");
      } else {
        register(user);
      }
    })
    .catch((err) => {
      register(user);
    });
};

const register = async (user) => {
  let params = {
    uid: user.uid,
    name: user.displayName,
    authProvider: "google",
    email: user.email,
    password: "",
    phone: "",
    role: ["user"],
  };

  HTTP_CLIENT(APIFY("/users/register"), "POST", params)
    .then((data) => {
      const user = data.data;
      loginwithuid(user.uid);
    })
    .catch((err) => {});
};

const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const registerWithEmailAndPassword = async (name, email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    await addDoc(collection(db, "users"), {
      uid: user.uid,
      name,
      authProvider: "local",
      email,
    });
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logout = () => {
  signOut(auth);
};

firebase.initializeApp(firebaseConfig);

export async function loginUser(email, password) {
  return await firebase
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then(async (result) => {
      if (result.user) {
        return true;
      }
    })
    .catch((error) => {
      alert(error.message);
    });
}

export async function registerUser(email, password, name, phone) {
  try {
    const res = await firebase
      .auth()
      .createUserWithEmailAndPassword(email, password);

    const user = res.user;

    await addDoc(collection(db, "users"), {
      uid: user.uid,
      name,
      authProvider: "local",
      email,
      phone,
    });
    if (res) return true;
  } catch (error) {
    return false;
  }
}

export async function getData(name) {
  const docRef = doc(db, "cities", name);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    return { error: "No such document!" };
  }
}

export async function getDataByCollection(name) {
  let data = [];

  try {
    data = await HTTP_CLIENT(APIFY(`/${name}`), "GET", {})
      .then((res) => {
        if (res.data) {
          data = res.data;
        }

        return data;
      })
      .catch((err) => {});

    return data;
  } catch {
    return data;
  }
}

export async function getDataById(id) {
  let data = [];

  try {
    data = await HTTP_CLIENT(APIFY(`/${id}`), "GET", {})
      .then((res) => {
        if (res.data) {
          data = res.data;
          console.log("data by id----", data);
        }

        return data;
      })
      .catch((err) => {
        console.log("errorr", err);
      });

    return data;
  } catch {
    return data;
  }
}

export async function getFilteredData(colle, key, value) {
  let data = [];

  try {
    data = await HTTP_CLIENT(
      APIFY(`/${colle}`),
      "GET",
      {},
      {
        [`${key}`]: value,
        limit: 0,
      }
    )
      .then((res) => {
        if (res.data) {
          data = res.data;
        }

        return data;
      })
      .catch((err) => {});

    return data;
  } catch {
    return data;
  }
}

export async function getMultiFilteredData(colle, filter) {
  let data = [];

  try {
    data = await HTTP_CLIENT(
      APIFY(`/${colle}`),
      "GET",
      {},
      {
        ...filter,
        limit: 0,
      }
    )
      .then((res) => {
        if (res.data) {
          data = res.data;
        }

        return data;
      })
      .catch((err) => {});

    return data;
  } catch {
    return data;
  }
}

export async function getStateList(payload) {
  let data = [];
  try {
    data = await HTTP_CLIENT(APIFY(`/country/state-city`), "GET", null, payload)
      .then((res) => {
        if (res.data) {
          data = res.data;
        }

        return data;
      })
      .catch((err) => {});

    return data;
  } catch {
    return data;
  }
}
export async function getLatestAppVersion() {
  let data = [];
  console.log("i am called.");
  try {
    data = await HTTP_CLIENT(APIFY(`/app-version`), "GET", null, null)
      .then((res) => {
        data = res;
        return data;
      })
      .catch((err) => {});

    return data;
  } catch {
    return data;
  }
}

export async function getCityData(key, value) {
  let data = [];

  try {
    data = await HTTP_CLIENT(APIFY(`/district/search`), "POST", {
      state: key,
      city: value,
      limit: 0,
    })
      .then((res) => {
        if (res.data) {
          data = res.data;
        }

        return data;
      })
      .catch((err) => {});

    return data;
  } catch {
    return data;
  }
}

export async function QueryDistrictUpdate() {
  if (false) {
    let data = [];
    const q = query(collection(db, "places"));
    const querySnapshot = await getDocs(q);

    let count = 0;
    let countAll = 0;
    querySnapshot.forEach(async (doc) => {
      countAll++;

      if (countAll >= 2000 && countAll < 3000) {
        // doc.data() is never undefined for query doc snapshots
        let temp = {
          ...doc.data(),
        };

        delete temp.id;
        let editorState = EditorState.createWithContent(
          convertFromRaw(JSON.parse(temp?.eventdetails))
        );

        temp.eventdetails = draftToHtml(
          convertToRaw(editorState?.getCurrentContent())
        );

        HTTP_CLIENT(APIFY("/places"), "POST", temp)
          .then((data) => {
            count++;
          })
          .catch((err) => {
            data.push(temp);
          });
      }
    });
  }
}

export function sendEmailVerification(email) {}

export {
  auth,
  db,
  signInWithGoogle,
  signInWithFacebook,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
};

export default { app };
