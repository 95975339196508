import React, { useState, useEffect } from "react";
import {
  IonPage,
  IonHeader,
  IonContent,
  IonToolbar,
  IonButton,
  IonTitle,
  IonLoading,
  IonModal,
  IonSlides,
  IonSlide,
  IonList,
  IonItem,
  IonLabel,
  IonListHeader,
  IonAvatar,
  IonThumbnail,
  IonSkeletonText,
} from "@ionic/react";
import { Button } from "@mui/material";
import { withRouter } from "react-router";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, A11y } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import PlayArrow from "@mui/icons-material/KeyboardDoubleArrowRight";
import CloseIcon from "@mui/icons-material/Close";
import { InAppBrowser } from "@awesome-cordova-plugins/in-app-browser";
import Adds from "../../components/showadds";
import Carousel from "react-multi-carousel";
import logo from "../../images/logo.png";
import { getCityData } from "../../modalconfig";
import { CallNumber } from "@awesome-cordova-plugins/call-number";
import starOutline from "../../images/star_outline.png";
import downarrow from "../../images/star.png";
import { useLocation } from "react-router-dom";
import { FirebaseAnalytics } from "@capacitor-firebase/analytics";
import DesktopHeader from "../../components/DesktopHeader";
import { APIFY, HTTP_CLIENT } from "../../services/Api";
import {
  getCityInLang,
  getLangLabel,
  getLangState,
} from "../../utility/languageTranslator";

const ViewMap = ({ history }) => {
  const location = useLocation();
  const fromViewPlace = location.state?.fromViewPlace || false;
  const [data, setDetails] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [topImages, setTopImages] = useState([]);
  const [websitetopImages, setwebsiteTopImages] = useState([]);
  const [mustVistPlaces, setMustVisitPlaces] = useState([]);
  const [mustVistTypes, setMustVistTypes] = useState([]);
  const [showLoading, setShowLoading] = useState(true);
  const [modalDetails, setModalDetails] = useState({});
  const [imageUrl, setImageUrl] = useState(null);
  const [stateName, setStateName] = useState();
  const [langCode, setlangCode] = useState("en");
  const [langTypes, setlangTypes] = useState([]);
  const [selected, setSelected] = useState(false);
  const [showCityName, SetshowCityName] = useState("");

  function countTextContent(htmlContent) {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlContent;
    const textContent = tempDiv.textContent || tempDiv.innerText || '';
    return textContent.length;
}
  const setAnalyticsScreen = async () => {
    try {
      await FirebaseAnalytics.setCurrentScreen({
        screenName: `District Details: ${showCityName}, ${stateName}`,
        screenClass: "District Details",
      });
      await FirebaseAnalytics.logEvent({
        name: "screen_view",
        params: {
          screen_name: `District Details: ${showCityName}, ${stateName}`,
          screen_class: "District Details",
          additional_context: { state: stateName, city: showCityName },
        },
      });
      console.log("Screen view event logged");
    } catch (error) {
      console.error("Error logging screen view event:", error);
    }
  };
  useEffect(() => {
    if (showCityName && stateName) setAnalyticsScreen();
  }, [showCityName, stateName]);

  useEffect(() => {
    setDetails([]);
    setMustVisitPlaces([]);
    setMustVistTypes([]);
    setShowLoading(true);
    const languageCode = localStorage.getItem("languageCode");
    setlangCode(languageCode || "en");
    const fetchData = async () => {
      const placeDetails = await JSON.parse(
        localStorage.getItem("placeDetails")
      );
      setStateName(placeDetails?.state);
      const netAvail = localStorage.getItem("Networkstatus");
      if (netAvail === "false") {
        let gettingDownloads =
          JSON.parse(localStorage.getItem("myDownloads")) || [];
        if (gettingDownloads?.length === 0) setShowLoading(false);
        let matchingDistrict = gettingDownloads.find(
          (item) =>
            (item.district ? item.district.toLowerCase() : "").trim() ===
            (placeDetails?.city
              ? placeDetails?.city?.toLowerCase()
              : ""
            )?.trim()
        );

        if (matchingDistrict.citydata?.length > 0) {
          setDetails(matchingDistrict.citydata);

          let temp_top = matchingDistrict.citydata.filter(
            (item) => item.key === "top"
          );
          setTopImages(temp_top?.length > 0 ? temp_top[0]?.images : []);

          let tempWebsiteTop = matchingDistrict.citydata.filter(
            (item) => item.key === "website-top"
          );

          if (tempWebsiteTop.length > 0) {
            setwebsiteTopImages(tempWebsiteTop[0].images);
          } else {
            setwebsiteTopImages(temp_top.length > 0 ? temp_top[0].images : "");
          }
        }

        setMustVistTypes(matchingDistrict.types);
        setMustVisitPlaces(matchingDistrict.places);

        setShowLoading(false);
      } else {
        SetshowCityName(placeDetails?.city);
        const res = await getCityData(placeDetails?.state, placeDetails?.city);
        if (res?.length > 0) {
          setDetails(res);
          const temp_top = res?.filter((item) => item?.key === "top");
          setTopImages(temp_top[0]?.images || []);

          let tempWebsiteTop = res?.filter(
            (item) => item?.key === "website-top"
          );
          if (tempWebsiteTop.length > 0) {
            setwebsiteTopImages(tempWebsiteTop[0].images);
            console.log("web-top", tempWebsiteTop[0].images);
          } else {
            setwebsiteTopImages(temp_top[0]?.images || []);
            console.log("web-top", temp_top[0]?.images || []);
          }
        }
        const placesRes = await HTTP_CLIENT(APIFY("/places"), "GET", null, {
          state: placeDetails?.state,
          city: placeDetails?.city,
        });
        const filerPlaces = placesRes.data;
        setMustVisitPlaces(filerPlaces);
        setShowLoading(false);

        let filters = await JSON.parse(localStorage.getItem("filters"));
        let filterTypes = [];
        filerPlaces?.map((item) => {
          // if(item?.type[0]==="Things To Do")
          //   return null;
          if (!filterTypes?.includes(item?.type)) {
            filterTypes.push(item?.type);
          }
        });
        let filterTypesList = [];
        let langList = [];
        filters?.map((filter) => {
          if (
            filterTypes?.some((subarray) => subarray?.includes(filter?.label))
          ) {
            langList.push(filter?.label_lang?.hi);
            filterTypesList.push(filter?.label);
          }
        });
        setMustVistTypes(filterTypesList);
        setlangTypes(langList);
      }
    };

    fetchData();
  }, [location.key]);

  var target = "_self";
  var iaboptions = {
    location: "yes",
    hideurlbar: "yes", //Or 'no'
    hidenavigationbuttons: "yes",
    fullscreen: "yes",
    toolbarcolor: "#000000",
    closebuttoncaption: "exit",
    clearcache: "yes",
  };

  ////save data for download
  const downloadData = () => {
    setSelected(true);
    const staredValues = JSON.parse(localStorage.getItem("staredValues")) || [];
    staredValues.push(`${stateName}_${showCityName}`);
    localStorage.setItem("staredValues", JSON.stringify(staredValues));
    const existingDownloads =
      JSON.parse(localStorage.getItem("myDownloads")) || [];

    const districtObjs = [
      {
        state: stateName,
        district: showCityName,
        types: mustVistTypes,
        places: mustVistPlaces,
        citydata: data,
        starvalue: true,
      },
    ];

    // Check if the district value already exists in the existing downloads
    const districtExists = existingDownloads.some(
      (download) => download.district === showCityName
    );

    if (districtExists) {
      alert(getLangLabel(langCode)?.alreadyDownloaded);
      return;
    }

    // Concatenate the new objects with the existing data or create a new array if there's no existing data
    const updatedArray = existingDownloads.concat(districtObjs);
    localStorage.setItem("myDownloads", JSON.stringify(updatedArray));
    alert(getLangLabel(langCode)?.dataDownloaded);
  };
  useEffect(() => {
    setSelected(false);
    const staredValues = JSON.parse(localStorage.getItem("staredValues")) || [];
    const foundValue = staredValues.find(
      (value) => value === `${stateName}_${showCityName}`
    );
    if (foundValue) {
      setSelected(true);
    }
  }, [stateName, showCityName, location.key]);

  const call = (number) => {
    CallNumber.callNumber(number, true)
      .then((res) => {})
      .catch((res) => {});
  };

  const inAppBrowser = (url) => {
    InAppBrowser.create(url, target, iaboptions);
  };
  const responsiveimg = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  const mustVisitPlacesList = () => {
    return mustVistTypes?.map((item, index) => {
      return (
        <div className="d_card" key={index}>
          {langCode === "hi" ? <h4>{langTypes[index]}</h4> : <h4>{item}</h4>}

          <div className="forMbl">
            <Carousel
              swipeable={true}
              draggable={true}
              showDots={
                mustVistPlaces?.filter((_item) => _item.type.includes(item))
                  ?.length === 1
                  ? false
                  : true
              }
              responsive={responsiveimg}
              ssr={true}
              infinite={false}
              autoPlay={false}
              autoPlaySpeed={3000}
              keyBoardControl={true}
              customTransition="all 1.2s ease 0s"
              transitionDuration={1000}
              containerClass="carousel-container carousel-list"
              removeArrowOnDeviceType={["tablet", "mobile"]}
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
              slidesToSlide="1"
              arrows={
                mustVistPlaces?.filter((_item) => _item.type.includes(item))
                  ?.length === 1
                  ? false
                  : true
              }
            >
              {mustVistPlaces?.filter((_item) => _item.type.includes(item))
                ?.length > 0 ? (
                mustVistPlaces
                  ?.filter((_placesitem) => _placesitem.type.includes(item))
                  ?.map((itemP, i) => (
                    <div
                      style={{
                        textAlign: "center",
                        marginBottom: "2rem",
                      }}
                      onClick={() => {
                        localStorage.setItem(
                          "placeDetails",
                          JSON.stringify(itemP)
                        );
                        history.push("/particularplaceinformation");
                      }}
                      key={i}
                    >
                      {itemP?.img?.[0] && (
                        <img
                          alt="places img"
                          src={itemP?.img?.[0]}
                          style={{
                            height: "280px",
                            width: "100%",
                            objectFit: "cover",
                          }}
                        />
                      )}
                      <h6>
                        {itemP?.name_lang?.[langCode]
                          ? itemP?.name_lang?.[langCode]
                          : itemP?.name}
                      </h6>
                    </div>
                  ))
              ) : (
                <>
                  <div>
                    <img alt=" " src="./assets/icon/Districtbg.jpg" />
                  </div>
                </>
              )}
            </Carousel>
          </div>
          <div className="forDesktop img-card">
            <Swiper
              modules={[Navigation, Pagination, A11y]}
              className="carousel-container carousel-list"
              navigation
              pagination={{ clickable: true }}
              spaceBetween={10}
              slidesPerView={3}
              speed={500}
              dots={true}
              resistanceRatio={0}
              freeMode={false}
              breakpoints={{
                575: {
                  slidesPerView: 3,
                },
                768: {
                  slidesPerView: 3,
                },
                1025: {
                  slidesPerView: 3,
                },
                1400: {
                  slidesPerView: 3,
                },
              }}
              loop={false}
            >
              {mustVistPlaces?.filter((_item) => _item.type.includes(item))
                ?.length > 0 ? (
                mustVistPlaces
                  ?.filter((_placesitem) => _placesitem.type.includes(item))
                  ?.map((itemP, i) => (
                    <SwiperSlide
                      key={i}
                      className="carousel-item-padding-40-px"
                    >
                      <div
                        className={`caros-card ${
                          item === "Suggested Itinerary" ? "itinerary" : ""
                        }`}
                        onClick={() => {
                          localStorage.setItem(
                            "placeDetails",
                            JSON.stringify(itemP)
                          );
                          history.push("/particularplaceinformation");
                        }}
                        style={{
                          paddingBottom: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        {itemP?.img?.[0] && (
                          <img alt="places img" src={itemP?.img?.[0]} />
                        )}
                        <h5 style={{ textAlign: "center" }}>
                          {itemP?.name_lang?.[langCode]
                            ? itemP?.name_lang?.[langCode]
                            : itemP?.name}
                        </h5>
                      </div>
                    </SwiperSlide>
                  ))
              ) : (
                <>
                  <div>
                    <img alt=" " src="./assets/icon/Districtbg.jpg" />
                  </div>
                </>
              )}
            </Swiper>
          </div>
        </div>
      );
    });
  };
  return (
    <IonPage>
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={getLangLabel(langCode)?.pleaseWait}
      />
      <IonHeader style={{ background: "#fff" }}>
        <IonToolbar className="t-bar">
          <div className="top-nav">
            <div className="nav_inner">
              <ArrowBackOutlinedIcon
                className="color-white"
                onClick={() => history.goBack()}
              />

              <h3 className="title-txt">
                {!showLoading &&
                  (langCode === "en" ? stateName : getLangState()?.[stateName])}
              </h3>

              <img
                onClick={() => history.push("/home")}
                src={logo}
                width={50}
                height={40}
                alt=""
              />
            </div>
          </div>
        </IonToolbar>
        <DesktopHeader />
      </IonHeader>
      <IonContent>
        <div className="columnContainer">
          <div className="bannerLeft">
            {websitetopImages?.length > 0 && (
              <Carousel
                swipeable={true}
                draggable={false}
                showDots={false}
                responsive={responsiveimg}
                ssr={true} // means to render carousel on server-side.
                infinite={false}
                autoPlay={false}
                autoPlaySpeed={3000}
                keyBoardControl={true}
                customTransition="all 1.2s ease 0s"
                transitionDuration={1000}
                containerClass="carousel-container carousel-list"
                removeArrowOnDeviceType={["tablet", "mobile"]}
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
                slidesToSlide="1"
                arrows={false}
              >
                {websitetopImages?.length > 0 ? (
                  websitetopImages?.map((item) => {
                    return (
                      <div>
                        {item && (
                          <img
                            alt=" "
                            src={item}
                            style={{ height: "100vh", width: "100%" }}
                          />
                        )}
                      </div>
                    );
                  })
                ) : (
                  <>
                    <div>
                      <img alt=" " src="./assets/icon/Districtbg.jpg" />
                    </div>
                  </>
                )}
              </Carousel>
            )}
            {!fromViewPlace && (
              <div
                className=""
                style={{
                  position: "absolute",
                  top: 10,
                  right: 10,
                  textAlign: "right",
                  display: "none",
                }}
                onClick={downloadData}
              >
                <div
                  style={{
                    background: "#e4e4e4",
                    borderRadius: "50%",
                    padding: "5px",
                    display: "inline-block",
                  }}
                >
                  {selected ? (
                    <img
                      src={downarrow}
                      className="img-fluid"
                      style={{ width: "30px" }}
                      alt=""
                    />
                  ) : (
                    <img
                      src={starOutline}
                      className="img-fluid"
                      style={{ width: "30px" }}
                      alt=""
                    />
                  )}
                </div>
              </div>
            )}
            <div className="labelDiv">
              {!showLoading && showCityName
                ? `${getLangLabel(langCode)?.welcomeTo} ${
                    langCode === "en"
                      ? showCityName
                      : getCityInLang(showCityName, langCode)
                  }`
                : `loading...`}
            </div>
          </div>

          <div className="bg-grey">
            <div className="container">
              <div className="" style={{ position: "relative" }}>
                {topImages?.length === 0 && (
                  <>
                    <div className="ion-padding custom-skeleton">
                      <IonSkeletonText animated style={{ width: "60%" }} />
                      <IonSkeletonText animated />
                      <IonSkeletonText animated style={{ width: "88%" }} />
                      <IonSkeletonText animated style={{ width: "70%" }} />
                      <IonSkeletonText animated style={{ width: "60%" }} />
                    </div>
                    <IonList>
                      <IonListHeader>
                        <IonLabel>
                          <IonSkeletonText animated style={{ width: "20%" }} />
                        </IonLabel>
                      </IonListHeader>
                      <IonItem>
                        <IonAvatar slot="start">
                          <IonSkeletonText animated />
                        </IonAvatar>
                        <IonLabel>
                          <h3>
                            <IonSkeletonText
                              animated
                              style={{ width: "50%" }}
                            />
                          </h3>
                          <p>
                            <IonSkeletonText
                              animated
                              style={{ width: "80%" }}
                            />
                          </p>
                          <p>
                            <IonSkeletonText
                              animated
                              style={{ width: "60%" }}
                            />
                          </p>
                        </IonLabel>
                      </IonItem>
                      <IonItem>
                        <IonThumbnail slot="start">
                          <IonSkeletonText animated />
                        </IonThumbnail>
                        <IonLabel>
                          <h3>
                            <IonSkeletonText
                              animated
                              style={{ width: "50%" }}
                            />
                          </h3>
                          <p>
                            <IonSkeletonText
                              animated
                              style={{ width: "80%" }}
                            />
                          </p>
                          <p>
                            <IonSkeletonText
                              animated
                              style={{ width: "60%" }}
                            />
                          </p>
                        </IonLabel>
                      </IonItem>
                      <IonItem>
                        <IonSkeletonText
                          animated
                          style={{ width: "27px", height: "27px" }}
                          slot="start"
                        />
                        <IonLabel>
                          <h3>
                            <IonSkeletonText
                              animated
                              style={{ width: "50%" }}
                            />
                          </h3>
                          <p>
                            <IonSkeletonText
                              animated
                              style={{ width: "80%" }}
                            />
                          </p>
                          <p>
                            <IonSkeletonText
                              animated
                              style={{ width: "60%" }}
                            />
                          </p>
                        </IonLabel>
                      </IonItem>
                    </IonList>
                  </>
                )}

                {topImages?.length > 0 && (
                  <Carousel
                    swipeable={true}
                    draggable={false}
                    showDots={true}
                    responsive={responsiveimg}
                    ssr={true} // means to render carousel on server-side.
                    infinite={false}
                    autoPlay={false}
                    autoPlaySpeed={3000}
                    keyBoardControl={true}
                    customTransition="all 1.2s ease 0s"
                    transitionDuration={1000}
                    containerClass="carousel-container carousel-list forMbl"
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px"
                    slidesToSlide="1"
                    arrows={true}
                  >
                    {topImages?.length > 0 ? (
                      topImages?.map((item) => {
                        return (
                          <div>
                            {item && (
                              <img
                                alt=" "
                                src={item}
                                style={{ height: "280px", width: "100%" }}
                              />
                            )}
                          </div>
                        );
                      })
                    ) : (
                      <>
                        <div>
                          <img alt=" " src="./assets/icon/Districtbg.jpg" />
                        </div>
                      </>
                    )}
                  </Carousel>
                )}
                <div
                  className="banr-box forMbl"
                  style={{
                    position: "absolute",
                    bottom: "30px",
                    left: 0,
                    right: 0,
                    textAlign: "center",
                  }}
                >
                  <h5
                    className=""
                    style={{
                      color: "#fff",
                      fontSize: "20px",
                      fontWeight: "500px",
                      textAlign: "center",
                      display: "inline-block",
                      margin: "0 0 7px 0",
                      backgroundColor: "#00000066",
                      padding: "5px",
                    }}
                  >
                    {!showLoading && showCityName
                      ? `${getLangLabel(langCode)?.welcomeTo} ${
                          langCode === "en"
                            ? showCityName
                            : getCityInLang(showCityName, langCode)
                        }`
                      : `loading...`}
                  </h5>
                </div>

                {!fromViewPlace && (
                  <div
                    className="forMbl"
                    style={{
                      position: "absolute",
                      top: 10,
                      right: 10,
                      textAlign: "right",
                    }}
                    onClick={downloadData}
                  >
                    <div
                      style={{
                        background: "#e4e4e4",
                        borderRadius: "50%",
                        width: "44px",
                        height: "44px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {selected ? (
                        <img
                          src={downarrow}
                          className="img-fluid"
                          style={{ width: "30px" }}
                          alt=""
                        />
                      ) : (
                        <img
                          src={starOutline}
                          className="img-fluid"
                          style={{ width: "30px" }}
                          alt=""
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>

              {data?.map((item) => {
                if (
                  item?.key === "top" ||
                  item?.key === "famous-for" ||
                  item?.key === "website-top"
                ) {
                  return null;
                }

                if (item?.type === "text") {
                  return (
                    <>
                      <div className="d_card text">
                        <h4>
                          {item?.label_lang?.[langCode]
                            ? item?.label_lang?.[langCode]
                            : item?.label}
                        </h4>
                        <p>
                          <div
                            className="text-card"
                            dangerouslySetInnerHTML={{
                              __html: item?.text_lang?.[langCode]
                                ? item?.text_lang?.[langCode]
                                : item?.text,
                            }}
                          ></div>
                        </p>
                      </div>
                      {item?.key === "nearest-railway-station" &&
                        mustVisitPlacesList()}
                    </>
                  );
                }
                if (item?.type === "url") {
                  return (
                    <>
                      <div className="d_card url">
                        <h4>
                          {item?.label_lang?.[langCode]
                            ? item?.label_lang?.[langCode]
                            : item?.label}
                        </h4>
                        <div
                          className="text-card"
                          onClick={() => inAppBrowser(item.url)}
                        >
                          <span style={{ color: "blue" }}>{item.url}</span>
                        </div>
                      </div>
                      {item?.key === "nearest-railway-station" &&
                        mustVisitPlacesList()}
                    </>
                  );
                }
                if (item?.type === "phone") {
                  return (
                    <>
                      <div className="d_card phone">
                        <h4>{item?.label}</h4>
                        <p onClick={() => call(item?.phone)}>
                          <span style={{ color: "blue" }}>{item?.phone}</span>
                        </p>
                      </div>
                      {item?.key === "nearest-railway-station" &&
                        mustVisitPlacesList()}
                    </>
                  );
                }
                if (item?.type === "img") {
                  return (
                    <>
                      <div className="d_card image">
                        <h4>
                          {item?.label_lang?.[langCode]
                            ? item?.label_lang?.[langCode]
                            : item?.label}
                        </h4>
                        {item?.images?.length > 1 ? (
                          <Carousel
                            swipeable={true}
                            draggable={true}
                            showDots={true}
                            responsive={responsiveimg}
                            ssr={true} // means to render carousel on server-side.
                            infinite={false}
                            autoPlay={false}
                            autoPlaySpeed={3000}
                            keyBoardControl={true}
                            customTransition="all 1.2s ease 0s"
                            transitionDuration={1000}
                            containerClass="carousel-container carousel-list"
                            removeArrowOnDeviceType={["tablet", "mobile"]}
                            dotListClass="custom-dot-list-style"
                            itemClass="carousel-item-padding-40-px"
                            slidesToSlide="1"
                            arrows={true}
                          >
                            {item?.images?.map((item) => {
                              return (
                                <div style={{ textAlign: "center" }}>
                                  {item && (
                                    <img
                                      alt=" "
                                      src={item}
                                      style={{ height: "220px" }}
                                      onClick={async () => {
                                        await setShowImage(false);
                                        await setImageUrl(item);
                                        await setShowImage(true);
                                      }}
                                    />
                                  )}
                                </div>
                              );
                            })}
                          </Carousel>
                        ) : (
                          <div
                            className="text-card"
                            style={{ textAlign: "center", display: "block" }}
                            onClick={async () => {
                              await setShowImage(false);
                              await setImageUrl(item.images[0]);
                              await setShowImage(true);
                            }}
                          >
                            {item.images[0] && (
                              <img
                                alt=" "
                                src={item?.images[0]}
                                style={{ height: "220px" }}
                              />
                            )}
                          </div>
                        )}
                      </div>
                      {item?.key === "nearest-railway-station" &&
                        mustVisitPlacesList()}
                    </>
                  );
                }
                if (item?.type === "sub") {
                  return (
                    <>
                      <div className="d_card">
                        <h4>
                          {item?.label_lang?.[langCode]
                            ? item.label_lang?.[langCode]
                            : item?.label}
                        </h4>
                        {item.subD.length > 0 ? (
                          <>
                            <div className="forMbl">
                              <Carousel
                                swipeable={true}
                                draggable={true}
                                showDots={true}
                                responsive={responsiveimg}
                                ssr={true} // means to render carousel on server-side.
                                infinite={false}
                                autoPlay={false}
                                autoPlaySpeed={3000}
                                keyBoardControl={true}
                                customTransition="all 1.2s ease 0s"
                                transitionDuration={2000}
                                containerClass="carousel-container"
                                removeArrowOnDeviceType={["tablet", "mobile"]}
                                dotListClass="custom-dot-list-style"
                                itemClass="carousel-item-padding-40-px"
                                slidesToSlide="1"
                                arrows={false}
                              >
                                {item.subD.map((_sub) => {
                                  return (
                                    <div>
                                      {_sub.image !== "" && (
                                        <div style={{ textAlign: "center" }}>
                                          {_sub.image[0] && (
                                            <img
                                              alt=" "
                                              src={_sub.image[0]}
                                              style={{ height: "220px" }}
                                            />
                                          )}
                                        </div>
                                      )}
                                      <h5
                                        className="text-desktop-center"
                                        style={{
                                          fontWeight: "600",
                                          fontFamily: "sans-serif",
                                          fontSize: "1rem",
                                          marginLeft: "8px",
                                          marginBottom: "20px",
                                        }}
                                      >
                                        {_sub?.sublabel_lang?.[langCode]
                                          ? _sub?.sublabel_lang?.[langCode]
                                          : _sub?.sublabel}
                                      </h5>
                                      {_sub?.text !== "" && (
                                        <p
                                          style={{
                                            margin: "2px 8px 20px",
                                            display: "-webkit-box",
                                            WebkitLineClamp: 4,
                                            overflow: "hidden",
                                            WebkitBoxOrient: "vertical",
                                          }}
                                        >
                                          <div
                                            dangerouslySetInnerHTML={{
                                              __html: _sub?.text_lang?.[
                                                langCode
                                              ]
                                                ? _sub.text_lang?.[langCode]
                                                : _sub?.text,
                                            }}
                                          ></div>
                                        </p>
                                      )}
                                      {_sub.url !== "" && (
                                        <p
                                          onClick={() => inAppBrowser(_sub.url)}
                                          className="text-desktop-center"
                                        >
                                          <span
                                            style={{
                                              color: "blue",
                                              marginLeft: "8px",
                                              marginRight: "8px",
                                            }}
                                          >
                                            {_sub.url}
                                          </span>
                                        </p>
                                      )}
                                      {_sub.phone !== "" && (
                                        <p
                                          style={{
                                            color: "blue",
                                            marginLeft: "8px",
                                          }}
                                          onClick={() => call(_sub.phone)}
                                        >
                                          {_sub.phone}
                                        </p>
                                      )}
                                      {_sub?.text !== "" &&countTextContent(_sub?.text)
                                         > 184 && (
                                          <Button
                                            style={{
                                              marginBottom: "40px",
                                              background: "transparent",
                                              border: "none",
                                              margin: "0 auto",
                                              display: "block",
                                            }}
                                            onClick={async () => {
                                              await setIsOpen(false);
                                              await setModalDetails(_sub);
                                              await setIsOpen(true);
                                            }}
                                          >
                                            <PlayArrow
                                              style={{ color: "#000" }}
                                            />
                                          </Button>
                                        )}
                                      <div
                                        style={{ marginBottom: "30px" }}
                                      ></div>
                                    </div>
                                  );
                                })}
                              </Carousel>
                            </div>
                            <div className="forDesktop img-card">
                              <Swiper
                                modules={[Navigation, Pagination, A11y]}
                                className="carousel-container carousel-list"
                                navigation
                                pagination={{ clickable: true }}
                                spaceBetween={10}
                                slidesPerView={3}
                                speed={500}
                                dots={true}
                                slidesOffsetAfter={10}
                                slidesOffsetBefore={0}
                                resistanceRatio={0}
                                freeMode={false}
                                breakpoints={{
                                  575: {
                                    slidesPerView: 3,
                                  },
                                  768: {
                                    slidesPerView: 3,
                                  },
                                  1025: {
                                    slidesPerView: 3,
                                  },
                                  1400: {
                                    slidesPerView: 3,
                                  },
                                }}
                                loop={false}
                              >
                                {item.subD.map((_sub) => {
                                  return (
                                    <SwiperSlide className="carousel-item-padding-40-px">
                                      <div
                                        className={`caros-card `}
                                        style={{
                                          paddingBottom: "10px",
                                          marginBottom: "10px",
                                        }}
                                      >
                                        <div style={{ padding: "5px" }}>
                                          {_sub.image !== "" && (
                                            <div
                                              style={{ textAlign: "center" }}
                                            >
                                              {_sub.image[0] && (
                                                <img
                                                  alt=" "
                                                  src={_sub.image[0]}
                                                  style={{ height: "220px" }}
                                                />
                                              )}
                                            </div>
                                          )}
                                          <h5
                                            className="text-desktop-center"
                                            style={{
                                              fontWeight: "600",
                                              fontFamily: "sans-serif",
                                              fontSize: "1rem",
                                              marginLeft: "8px",
                                              marginBottom: "20px",
                                            }}
                                          >
                                            {_sub?.sublabel_lang?.[langCode]
                                              ? _sub?.sublabel_lang?.[langCode]
                                              : _sub?.sublabel}
                                          </h5>
                                          {_sub.text !== "" && (
                                            <p
                                              style={{
                                                margin: "2px 8px 20px",
                                              }}
                                            >
                                              <div
                                                className="multiline-ellipsis"
                                                dangerouslySetInnerHTML={{
                                                  __html: _sub?.text_lang?.[
                                                    langCode
                                                  ]
                                                    ? _sub?.text_lang?.[
                                                        langCode
                                                      ]
                                                    : _sub?.text,
                                                }}
                                              ></div>
                                            </p>
                                          )}
                                          {_sub.url !== "" && (
                                            <p
                                              className="text-desktop-center"
                                              onClick={() =>
                                                inAppBrowser(_sub.url)
                                              }
                                            >
                                              <span
                                                style={{
                                                  color: "blue",
                                                  marginLeft: "8px",
                                                  marginRight: "8px",
                                                }}
                                              >
                                                {_sub.url}
                                              </span>
                                            </p>
                                          )}
                                          {_sub.phone !== "" && (
                                            <p
                                              className="text-desktop-center"
                                              style={{
                                                color: "blue",
                                                marginLeft: "8px",
                                                marginRight: "8px",
                                              }}
                                              onClick={() => call(_sub.phone)}
                                            >
                                              {_sub.phone}
                                            </p>
                                          )}
                                          {_sub?.text !== "" &&
                                           countTextContent(_sub?.text)> 184 && (
                                              <Button
                                                style={{
                                                  marginBottom: "40px",
                                                  background: "transparent",
                                                  border: "none",
                                                  margin: "0 auto",
                                                  display: "block",
                                                }}
                                                onClick={async () => {
                                                  await setIsOpen(false);
                                                  await setModalDetails(_sub);
                                                  await setIsOpen(true);
                                                }}
                                              >
                                                <PlayArrow
                                                  style={{ color: "#000" }}
                                                />
                                              </Button>
                                            )}
                                        </div>
                                      </div>
                                    </SwiperSlide>
                                  );
                                })}
                              </Swiper>
                            </div>
                          </>
                        ) : (
                          <>
                            {item?.subD[0]?.image !== "" && (
                              <>
                                {item?.subD[0]?.image?.length > 1 ? (
                                  <Carousel
                                    swipeable={true}
                                    draggable={true}
                                    showDots={true}
                                    responsive={responsiveimg}
                                    ssr={true} // means to render carousel on server-side.
                                    infinite={false}
                                    autoPlay={false}
                                    autoPlaySpeed={3000}
                                    keyBoardControl={true}
                                    customTransition="all 1.2s ease 0s"
                                    transitionDuration={1000}
                                    containerClass="carousel-container carousel-list"
                                    removeArrowOnDeviceType={[
                                      "tablet",
                                      "mobile",
                                    ]}
                                    dotListClass="custom-dot-list-style"
                                    itemClass="carousel-item-padding-40-px"
                                    slidesToSlide="1"
                                    arrows={true}
                                  >
                                    {item.subD[0].image.map((item) => {
                                      return (
                                        <div style={{ textAlign: "center" }}>
                                          {item && (
                                            <img
                                              alt=" "
                                              src={item}
                                              style={{ height: "220px" }}
                                            />
                                          )}
                                        </div>
                                      );
                                    })}
                                  </Carousel>
                                ) : (
                                  <div style={{ textAlign: "center" }}>
                                    {item?.subD[0]?.image[0] && (
                                      <img
                                        alt=" "
                                        src={item?.subD[0]?.image[0]}
                                        style={{ height: "220px" }}
                                      />
                                    )}
                                  </div>
                                )}
                              </>
                            )}
                            <h5
                              style={{
                                fontWeight: "600",
                                fontFamily: "sans-serif",
                                fontSize: "16px",
                              }}
                            >
                              {item?.subD[0]?.sublabel_lang?.[langCode]
                                ? item?.subD[0]?.sublabel_lang?.[langCode]
                                : item?.subD[0]?.sublabel}
                            </h5>
                            {item?.subD[0]?.text !== "" && (
                              <p
                                style={{
                                  margin: "2px 8px 20px",
                                  display: "-webkit-box",
                                  WebkitLineClamp: 4,
                                  overflow: "hidden",
                                  WebkitBoxOrient: "vertical",
                                }}
                              >
                                {" "}
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: item?.subD[0]?.text_lang?.[langCode]
                                      ? item?.subD[0]?.text_lang?.[langCode]
                                      : item?.subD[0]?.text,
                                  }}
                                ></div>
                              </p>
                            )}
                            {item?.subD[0]?.url !== "" && (
                              <p
                                onClick={() => inAppBrowser(item?.subD[0]?.url)}
                              >
                                <span style={{ color: "blue" }}>
                                  {item?.subD[0]?.url}
                                </span>
                              </p>
                            )}
                            {item?.subD[0]?.phone !== "" && (
                              <p
                                style={{ color: "blue" }}
                                onClick={() => call(item?.subD[0]?.phone)}
                              >
                                {item?.subD[0]?.phone}
                              </p>
                            )}
                            {item?.subD[0]?.text !== "" &&
                              countTextContent(item?.subD[0]?.text) >184 && (
                                <Button
                                  style={{
                                    marginBottom: "40px",
                                    background: "transparent",
                                    border: "none",
                                    margin: "0 auto",
                                    display: "block",
                                  }}
                                  onClick={async () => {
                                    await setIsOpen(false);
                                    await setModalDetails(item?.subD[0]);
                                    await setIsOpen(true);
                                  }}
                                >
                                  <PlayArrow style={{ color: "#000" }} />
                                </Button>
                              )}
                          </>
                        )}
                      </div>
                      {item?.key === "nearest-railway-station" &&
                        mustVisitPlacesList()}
                    </>
                  );
                }
              })}
            </div>
          </div>
          <IonModal isOpen={isOpen}>
            <IonHeader>
              <IonToolbar>
                <IonTitle>
                  {modalDetails?.sublabel_lang?.[langCode]
                    ? modalDetails?.sublabel_lang?.[langCode]
                    : modalDetails?.sublabel}
                </IonTitle>
                <Button
                  slot="end"
                  className="forDesktop"
                  style={{
                    background: "transparent",
                    border: "none",
                  }}
                  onClick={() => setIsOpen(false)}
                >
                  <CloseIcon style={{ color: "#000" }} />
                </Button>
              </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding white-ion-content-bg">
              {modalDetails?.image !== "" && (
                <Carousel
                  swipeable={true}
                  draggable={false}
                  showDots={true}
                  responsive={responsiveimg}
                  infinite={false}
                  autoPlay={false}
                  autoPlaySpeed={3000}
                  keyBoardControl={true}
                  customTransition="all 1.2s ease 0s"
                  transitionDuration={1000}
                  containerClass="carousel-container"
                  removeArrowOnDeviceType={["tablet", "mobile"]}
                  dotListClass="custom-dot-list-style"
                  itemClass="carousel-item-padding-40-px"
                  slidesToSlide="1"
                  arrows={true}
                >
                  {modalDetails?.image?.map((item) => {
                    return (
                      <div style={{ textAlign: "center" }}>
                        {item && (
                          <img alt=" " src={item} style={{ height: "220px" }} />
                        )}
                      </div>
                    );
                  })}
                </Carousel>
              )}
              {modalDetails?.text !== "" && (
                <div
                  style={{ lineHeight: "18px" }}
                  dangerouslySetInnerHTML={{
                    __html: modalDetails?.text_lang?.[langCode]
                      ? modalDetails?.text_lang?.[langCode]
                      : modalDetails?.text,
                  }}
                ></div>
              )}
              {modalDetails?.url !== "" && (
                <p onClick={() => inAppBrowser(modalDetails?.url)}>
                  <span style={{ color: "blue" }}>{modalDetails?.url}</span>
                </p>
              )}
              {modalDetails?.phone !== "" && (
                <p
                  style={{ color: "blue" }}
                  onClick={() => call(modalDetails?.phone)}
                >
                  {modalDetails?.phone}
                </p>
              )}
              <IonButton
                className="forMbl"
                expand="block"
                onClick={() => setIsOpen(false)}
              >
                Close
              </IonButton>
            </IonContent>
          </IonModal>
          <IonModal isOpen={showImage} className="modalCustom">
            <IonContent>
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div style={{ display: "flex" }} className="ion-padding">
                  <IonButton
                    color="danger"
                    expand="block"
                    style={{ float: "right", marginLeft: "auto" }}
                    onClick={() => setShowImage(false)}
                  >
                    X
                  </IonButton>
                </div>

                <div style={{ flex: "1" }}>
                  <IonSlides
                    options={{ zoom: { maxRatio: 3 } }}
                    zoom
                    style={{ height: "100%" }}
                  >
                    <IonSlide>
                      <div className="swiper-zoom-container">
                        <img
                          src={imageUrl}
                          style={{ padding: ".5rem" }}
                          alt=""
                        />
                      </div>
                    </IonSlide>
                  </IonSlides>
                </div>
              </div>
            </IonContent>
          </IonModal>
        </div>
      </IonContent>
      <Adds />
    </IonPage>
  );
};

export default withRouter(ViewMap);
