import React from "react";
import logo from "../images/logo.png";
import maplogo from "../images/Vector Smart Object.png";
import card1 from "../images/tiger.jpg";
import card2 from "../images/lotusTemple.jpg";
import card3 from "../images/temp1.png";
import txtimg from "../images/Group 3.png";

const AnimatedStartScreen = ({isAnimated}) => {
 
  return (
    <div className={`getStartedTransition ${isAnimated?"isAnimatedSplash":""}`}>
      <div className="AppLandingPage">
        <div className="spacer"></div>
        <img src={logo} className="img-fluid logo" alt="Logo" />
        <div className="spacer"></div>
        <img src={maplogo} className="img-fluid mapLogo" alt="Map Logo" />
        <div className="spacer"></div>
        <div className="tiles">
          <div
            className="logincard"
            style={{
              backgroundImage: `url(${card1})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPositionX: "-30px",
            }}
          ></div>
          <div className="logincard" style={{ marginTop: "60px" }}>
            <img alt=" " src={card3} />
          </div>
          <div className="logincard">
            <img alt=" " src={card2} />
          </div>
        </div>
        <div className="spacer"></div>
        <img src={txtimg} className="img-fluid textLogo" alt="Text Img" />
        <div className="spacer"></div>
      </div>
    </div>
  );
};

export default AnimatedStartScreen;
