// Utility function to remove HTML tags and inline styles
export const removeTagsAndStyles = (html) => {
  const div = document.createElement("div");
  div.innerHTML = html;
  const text = div.innerText;
  return text;
};

export const markers = [
  {
    markerOffset: -6,
    name: "Andaman & Nicobar Island (UT)",
    name_lang: {
      hi: "अंडमान और निकोबार द्वीप (यूटी)",
    },
    label: "Andaman & Nicobar Island",
    coordinates: [92.54, 9.43],
    color: "#f44336",
  },
  {
    markerOffset: -6,
    name: "Andhra Pradesh",
    name_lang: {
      hi: "आंध्र प्रदेश",
    },
    label: "Andhra Pradesh",
    coordinates: [78.54, 13.2],
    color: "#f8f070",
  },
  {
    markerOffset: -6,
    name: "Arunachal Pradesh",
    name_lang: {
      hi: "अरुणाचल प्रदेश",
    },
    label: "Arunachal Pradesh",
    coordinates: [94.74, 26.84],
    color: "#c7eaf6",
  },
  {
    markerOffset: -6,
    name: "Assam",
    name_lang: {
      hi: "असम",
    },
    label: "Assam",
    coordinates: [92.74, 24.84],
    color: "#cfc0df",
  },
  {
    markerOffset: -6,
    name: "Bihar",
    name_lang: {
      hi: "बिहार",
    },
    label: "Bihar",
    coordinates: [85.74, 24.14],
    color: "#F44336",
  },
  {
    markerOffset: -6,
    name: "Chandigarh (UT)",
    name_lang: {
      hi: "चंडीगढ़ (यूटी)",
    },
    label: "Chandigarh (UT)",
    coordinates: [75.5, 29.3],
    color: "#E3E4FA",
  },
  {
    markerOffset: -6,
    name: "Chhattisgarh",
    name_lang: {
      hi: "छत्तीसगढ",
    },
    label: "Chhattisgarh",
    coordinates: [81.98, 20.1],
    color: "#F44336",
  },
  {
    markerOffset: -6,
    name: "Dadra and Nagar Haveli (UT)",
    name_lang: {
      hi: "दादरा और नगर हवेली (यूटी)",
    },
    label: "Dadra and Nagar Haveli (UT)",
    coordinates: [70.5, 18.4],
    color: "#F44336",
  },
  {
    markerOffset: -6,
    name: "Daman and Diu (UT)",
    name_lang: {
      hi: "दमन और दीव (यूटी)",
    },
    label: "Daman and Diu (UT)",
    coordinates: [70.5, 18.8],
    color: "#f7bb52",
  },
  {
    markerOffset: -6,
    name: "Goa",
    name_lang: {
      hi: "गोवा",
    },
    label: "Goa",
    coordinates: [73.8, 13.74],
    color: "#8db0dd",
  },
  {
    markerOffset: -6,
    name: "Gujarat",
    name_lang: {
      hi: "गुजरात",
    },
    label: "Gujarat",
    coordinates: [71.98, 21.7],
    color: "#f7bb52",
  },
  {
    markerOffset: -6,
    name: "Haryana",
    name_lang: {
      hi: "हरियाणा",
    },
    label: "Haryana",
    coordinates: [76.2, 27.7],
    color: "#b3e2f7",
  },
  {
    markerOffset: -6,
    name: "Himachal Pradesh",
    name_lang: {
      hi: "हिमाचल प्रदेश",
    },
    label: "Himachal Pradesh",
    coordinates: [77.5, 30.6],
    color: "#f9bf98",
  },
  {
    markerOffset: -6,
    name: "Ladakh (UT)",
    name_lang: {
      hi: "लद्दाख (यूटी)",
    },
    label: "Ladakh",
    coordinates: [77.5, 32.6],
    color: "#f3d8e9",
  },
  {
    markerOffset: -6,
    name: "J & K (UT)",
    name_lang: {
      hi: "जम्मू और कश्मीर (यूटी)",
    },
    label: "Jammu and Kashmir",
    coordinates: [75.0, 31.8],
    color: "#b3e2f7",
  },
  {
    markerOffset: -6,
    name: "Jharkhand",
    name_lang: {
      hi: "झारखंड",
    },
    label: "Jharkhand",
    coordinates: [85.1, 22.2],
    color: "#f7c195",
  },
  {
    markerOffset: -6,
    name: "Karnataka",
    name_lang: {
      hi: "कर्नाटक",
    },
    label: "Karnataka",
    coordinates: [75.6, 12.84],
    color: "#afc4aa",
  },
  {
    markerOffset: -6,
    name: "Kerala",
    name_lang: {
      hi: "केरल",
    },
    label: "Kerala",
    coordinates: [76.6, 8.15],
    color: "#8cccf3",
  },
  {
    markerOffset: -6,
    name: "Lakshadweep (UT)",
    name_lang: {
      hi: "लक्षद्वीप (यूटी)",
    },
    label: "Lakshadweep",
    coordinates: [72.9, 9.15],
    color: "#00979e",
  },
  {
    markerOffset: -6,
    name: "Madhya Pradesh",
    name_lang: {
      hi: "मध्य प्रदेश",
    },
    label: "Madhya Pradesh",
    coordinates: [78.7, 21.5],
    color: "#d3bfde",
  },
  {
    markerOffset: -6,
    name: "Maharashtra",
    name_lang: {
      hi: "महाराष्ट्र",
    },
    label: "Maharashtra",
    coordinates: [75.3, 17.7],
    color: "#f6b9d4",
  },
  {
    markerOffset: -6,
    name: "Manipur",
    name_lang: {
      hi: "मणिपुर",
    },
    label: "Manipur",
    coordinates: [94.1, 23.2],
    color: "#f9bf95",
  },
  {
    markerOffset: -6,
    name: "Meghalaya",
    name_lang: {
      hi: "मेघालय",
    },
    label: "Meghalaya",
    coordinates: [91.1, 24.0],
    color: "#F44336",
  },
  {
    markerOffset: -6,
    name: "Mizoram",
    name_lang: {
      hi: "मिजोरम",
    },
    label: "Mizoram",
    coordinates: [93.3, 21.8],
    color: "#F44336",
  },
  {
    markerOffset: -6,
    name: "Nagaland",
    name_lang: {
      hi: "नगालैंड",
    },
    label: "Nagaland",
    coordinates: [94.8, 24.6],
    color: "#8db0dd",
  },
  {
    markerOffset: -6,
    name: "Delhi (UT)",
    name_lang: {
      hi: "दिल्ली (यूटी)",
    },
    label: "Delhi",
    coordinates: [77.2, 27.2],
    color: "#fad98f",
  },
  {
    markerOffset: -6,
    name: "",
    name_lang: {
      hi: "",
    },
    label: "",
    coordinates: [80.6, 10.15],
    color: "#f3d8e9",
  },
  {
    markerOffset: -6,
    name: "Punjab",
    name_lang: {
      hi: "पंजाब",
    },
    label: "Punjab",
    coordinates: [75.3, 30],
    color: "#b9dc9a",
  },
  {
    markerOffset: -6,
    name: "Rajasthan",
    name_lang: {
      hi: "राजस्थान",
    },
    label: "Rajasthan",
    coordinates: [73.5, 25.3],
    color: "#b3e2f7",
  },
  {
    markerOffset: -6,
    name: "Sikkim",
    name_lang: {
      hi: "सिक्किम",
    },
    label: "Sikkim",
    coordinates: [88.5, 26.14],
    color: "#fabe97",
  },
  {
    markerOffset: -6,
    name: "Tamil Nadu",
    name_lang: {
      hi: "तमिलनाडु",
    },
    label: "Tamil Nadu",
    coordinates: [78.4, 9.15],
    color: "#f3d8e9",
  },
  {
    markerOffset: -6,
    name: "Puducherry (UT)",
    name_lang: {
      hi: "पुडुचेरी (यूटी)",
    },
    label: "Puducherry (UT)",
    coordinates: [81.4, 10.15],
    color: "#f9bf92",
  },
  {
    markerOffset: -6,
    name: "Telangana",
    name_lang: {
      hi: "तेलंगाना",
    },
    label: "Telangana",
    coordinates: [79.2, 16.15],
    color: "#8db0dd",
  },
  {
    markerOffset: -6,
    name: "Tripura",
    name_lang: {
      hi: "त्रिपुरा",
    },
    label: "Tripura",
    coordinates: [91.5, 22.15],
    color: "#b9dc9a",
  },
  {
    markerOffset: -6,
    name: "Uttar Pradesh",
    name_lang: {
      hi: "उत्तर प्रदेश",
    },
    label: "Uttar Pradesh",
    coordinates: [80.74, 25.3],
    color: "#feef8d",
  },
  {
    markerOffset: -6,
    name: "Uttarakhand",
    name_lang: {
      hi: "उत्तराखंड",
    },
    label: "Uttarakhand",
    coordinates: [79.2, 28.75],
    color: "#afc4a7",
  },
  {
    markerOffset: -6,
    name: "West Bengal",
    name_lang: {
      hi: "पश्चिम बंगाल",
    },
    label: "West Bengal",
    coordinates: [87.5, 21.75],
    color: "#f7bd52",
  },
  {
    markerOffset: -6,
    name: "Odisha",
    name_lang: {
      hi: "ओडिशा",
    },
    label: "Odisha",
    coordinates: [84.5, 19.2],
    color: "#f4afc8",
  },
];
