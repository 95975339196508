import React, { useEffect, useState, useRef } from "react";
import {
  IonPage,
  IonHeader,
  IonContent,
  IonToolbar,
  IonButtons,
  useIonViewDidEnter,
  useIonViewDidLeave,
  IonItem,
  IonLabel,
  IonButton,
  IonModal,
  IonTextarea,
  IonTitle,
  IonImg,
  IonList,
  IonListHeader,
  IonAvatar,
  IonThumbnail,
  IonSkeletonText,
  IonLoading,
} from "@ionic/react";
import { useLocation, withRouter } from "react-router";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { getFilteredData } from "../../modalconfig";
import { EditorState } from "draft-js";
import { APIFY, HTTP_CLIENT } from "../../services/Api";
import remove from "../../images/remove.png";
import volume from "../../images/volume.png";
import mute from "../../images/mute.png";
import Resizer from "react-image-file-resizer";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { FirebaseAnalytics } from "@capacitor-firebase/analytics";
import { TextToSpeech } from "@capacitor-community/text-to-speech";
import DesktopHeader from "../../components/DesktopHeader";
import logo from "../../images/logo.png";
import {
  getCityInLang,
  getLangLabel,
  getLangState,
} from "../../utility/languageTranslator";
import { removeTagsAndStyles } from "../../utility/commonMethods";

function DisplayContent({ content }) {
  return <div dangerouslySetInnerHTML={{ __html: content }}></div>;
}

const ParticularPlaceInformation = ({ history }) => {
  const sectionRef = useRef(null);
  const location = useLocation();
  const [details, setDetails] = React.useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [ReviewTxt, setReviewTxt] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [PlaceN, setPlaceN] = useState("");
  const [reviews, setReviews] = useState([]);
  const [data, setData] = useState();
  const editorState = EditorState.createEmpty();
  const [images, setImages] = useState([]);
  const [serverImages, setServerImages] = useState([]);
  const [speech, setSpeech] = useState("");
  const [previewUrls, setPreviewUrls] = useState([]);
  const [nearbyPlaces, setNearbyPlaces] = useState([]);
  const [netsts, Setnetsts] = useState(false);
  const [langCode, setlangCode] = useState("en");
  const [leftImg, setleftImg] = useState([]);
  const [reloadKey, setReloadKey] = useState(0);

  const setAnalyticsScreen = async () => {
    try {
      await FirebaseAnalytics.setCurrentScreen({
        screenName: `Place Details: ${details?.name}, ${details?.city}, ${details?.state}`,
        screenClass: "Place Details",
      });
      await FirebaseAnalytics.logEvent({
        name: "screen_view",
        params: {
          screen_name: `Place Details: ${details?.name}, ${details?.city}, ${details?.state}`,
          screen_class: "Place Details",
          additional_context: {
            place: details?.name,
            state: details?.state,
            city: details?.city,
          },
        },
      });
      console.log("Screen view event logged");
    } catch (error) {
      console.error("Error logging screen view event:", error);
    }
  };
  useEffect(() => {
    if (details?.name) setAnalyticsScreen();
  }, [details?.name]);

  ///text to speech
  const speak = async () => {
    try {
      await TextToSpeech.speak({
        text: speech,
        lang: "en-US",
        rate: 1.0,
        pitch: 1.0,
        volume: 1.0,
        category: "ambient",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const stop = async () => {
    await TextToSpeech.stop();
  };

  const getNearbyPlaces = async (latitude, longitude, placeId) => {
    try {
      const res = await HTTP_CLIENT(
        APIFY(
          `/places/nearPlaces?latitude=${latitude}&longitude=${longitude}&id=${placeId}`
        ),
        "GET"
      );
      setNearbyPlaces(res.data);
    } catch (err) {
      console.log("error", err);
    }
  };

  const goToNearbyPlace = (place) => {
    localStorage.removeItem("placeDetails");
    localStorage.setItem("placeDetails", JSON.stringify(place));
    setReloadKey((prevKey) => prevKey + 1);
    history.push("/particularplaceinformation");
  };
  const getData = async () => {
    setTimeout(() => {
      if (sectionRef.current) {
        sectionRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 200);

    const netAvail = localStorage.getItem("Networkstatus");
    Setnetsts(netAvail);

    let data = await JSON.parse(localStorage.getItem("placeDetails"));
    if (
      data?.location?.coordinates[1] &&
      data?.location?.coordinates[0] &&
      data._id
    )
      getNearbyPlaces(
        data?.location?.coordinates[1],
        data?.location?.coordinates[0],
        data._id
      );
    setDetails(data);
    setData({});
    setPlaceN(data.name);
    setShowLoading(false);
    if (netAvail !== "false") {
      const languageCode = localStorage.getItem("languageCode");
      setlangCode(languageCode || "en");
      getReviews();
      setleftImg(data?.img[0]);
      const eventDetails = removeTagsAndStyles(data.eventdetails);
      setSpeech(eventDetails);
    }
  };
  useIonViewDidEnter(async () => {
    getData();
  }, [location.key]);
  useEffect(() => {
    if (reloadKey > 0) {
      getData();
    }
  }, [reloadKey]);

  const goBack = () => {
    history.goBack();
  };

  const handleImageChange = async (e) => {
    setShowLoading(true);
    const files = Array.from(e.target.files); // Convert FileList to array
    const newImages = [];
    const newPreviewUrls = [];
    try {
      // Resize all images concurrently
      await Promise.all(
        files.map(async (file) => {
          try {
            const resizedFile = await new Promise((resolve) => {
              Resizer.imageFileResizer(
                file,
                200, // Width
                200, // Height
                "JPEG", // Format
                100, // Quality
                0, // Rotation
                (resizedFile) => {
                  console.log("Resized file:", resizedFile);
                  resolve(resizedFile);
                },
                "blob", // Output type
                200, // Max width (optional)
                200 // Max height (optional)
              );
            });

            if (resizedFile instanceof Blob) {
              newImages.push(resizedFile);
              const imageUrl = URL.createObjectURL(resizedFile); // Convert Blob to object URL
              newPreviewUrls.push(imageUrl);
            } else {
              console.log("Resized file is not a Blob:", resizedFile);
            }
          } catch (error) {
            console.log("Error resizing image:", error);
            throw error; // Rethrow error to be caught by outer catch block
          }
        })
      );

      setImages([...images, ...newImages]);
      setPreviewUrls([...previewUrls, ...newPreviewUrls]);
      setShowLoading(false);

      // Now upload the images to the server
      await uploadImgToApi(newImages);
    } catch (error) {
      console.error("Error handling image change:", error);
      setShowLoading(false);
      // Provide feedback to the user about the error
      alert(getLangLabel(langCode)?.imagesError);
    }

    // Reset the input value to allow the same file to be selected again
    document.getElementById("uploadInput").value = "";
  };

  const uploadImgToApi = async (imagesToUpload) => {
    const uploadedUrls = [];

    for (let i = 0; i < imagesToUpload.length; i++) {
      const formdata = new FormData();
      const image = imagesToUpload[i];
      const filename = `${new Date().toISOString()}-${i}.png`;

      Object.defineProperty(image, "name", {
        writable: true,
        value: filename,
      });

      formdata.append("upload", image, filename);

      try {
        console.log(formdata, "uploadedimages---");
        const response = await HTTP_CLIENT(
          APIFY("/upload"),
          "POST",
          formdata,
          {},
          "formdata"
        );

        if (response && response.uploaded) {
          if (response.data && typeof response.data === "object") {
            const url = `${response.data.path}${response.data.filename}`;
            console.log("Uploaded URL:", url);
            uploadedUrls.push(url);
            console.log("uploadedUrls===", uploadedUrls);
            setServerImages((prevUrls) => [...prevUrls, url]);
          } else {
            console.error("Unexpected response data format:", response.data);
          }
        } else {
          console.error("Upload failed:", response.message);
        }
      } catch (err) {
        console.log(err);
      }
    }

    return uploadedUrls;
  };

  ///Remove Image
  const handleRemoveImage = (indexToRemove) => {
    setImages(images.filter((_, index) => index !== indexToRemove));
    setPreviewUrls(previewUrls.filter((_, index) => index !== indexToRemove));
    setServerImages(serverImages.filter((_, index) => index !== indexToRemove));
  };

  const handleCancel = () => {
    setIsOpen(false);
    setImages([]);
    setPreviewUrls([]);
    setServerImages([]);
    setReviewTxt("");
  };
  ///
  const getReviews = async () => {
    setReviews([]);
    let data = await JSON.parse(localStorage.getItem("placeDetails"));
    let Reviews = await getFilteredData("reviews", "reviewplace", data?.name);
    let sortedReview = Reviews.sort((a, b) => {
      return new Date(b.date) - new Date(a.date);
    });
    setReviews(sortedReview);
    console.log("sortedReview", sortedReview);
  };

  useIonViewDidLeave(() => {
    setDetails({});
    setIsOpen(false);
    TextToSpeech.stop();
  });

  const ReviewFn = async () => {
    setShowLoading(true);
    if (!ReviewTxt.trim()) {
      alert("Review cannot be empty");
      setShowLoading(false);
    } else {
      let data = {
        name: "Anonymous user",
        phone: "9999999999",
        email: "test@gmail.com",
        reviewplace: PlaceN,
        review: ReviewTxt,
        date: `${new Date()}`,
        state: details?.state,
        city: details?.city,
        img: serverImages,
      };

      console.log("Review data", data);
      HTTP_CLIENT(APIFY("/reviews"), "POST", data)
        .then(async (res) => {
          console.log("success", res);
          alert(getLangLabel(langCode)?.reviewSaved);
          setIsOpen(false);
          setImages([]);
          setPreviewUrls([]);
          setServerImages([]);
          setReviewTxt("");
          getReviews();
        })
        .catch((err) => {
          console.log("error", err);
        })
        .finally(() => {
          setShowLoading(false);
        });

      setIsOpen(false);
    }
  };

  const responsiveimg = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  useEffect(() => {}, [editorState]);
  return (
    <IonPage>
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={"Please wait..."}
      />
      <IonHeader style={{ background: "#fff" }}>
        <IonToolbar className="t-bar">
          <div className="top-nav">
            <div className="nav_inner">
              <ArrowBackOutlinedIcon className="color-white" onClick={goBack} />
              <img
                onClick={() => history.push("/home")}
                src={logo}
                width={50}
                height={40}
                alt="img"
              />
            </div>
          </div>
        </IonToolbar>
        <DesktopHeader />
      </IonHeader>
      <IonContent>
        {data ? (
          <>
            <div className="columnContainer">
              <div className="bannerLeft">
                <div className="labelDiv">
                  {details?.name_lang?.[langCode]
                    ? details?.name_lang?.[langCode]
                    : details?.name}
                </div>
                <Carousel
                  swipeable={true}
                  draggable={false}
                  showDots={false}
                  responsive={responsiveimg}
                  ssr={true} // means to render carousel on server-side.
                  infinite={false}
                  autoPlay={false}
                  autoPlaySpeed={3000}
                  keyBoardControl={true}
                  customTransition="all 1.2s ease 0s"
                  transitionDuration={1000}
                  containerClass="carousel-container carousel-list"
                  removeArrowOnDeviceType={["tablet", "mobile"]}
                  dotListClass="custom-dot-list-style"
                  itemClass="carousel-item-padding-40-px"
                  slidesToSlide="1"
                  arrows={false}
                >
                  {details?.img?.length > 0 ? (
                    details?.img?.map((item) => (
                      <div>
                        <img
                          alt=" "
                          src={item}
                          style={{ height: "100vh", width: "100%" }}
                        />
                      </div>
                    ))
                  ) : (
                    <>
                      <div>
                        <img alt=" " src="./assets/icon/Districtbg.jpg" />
                      </div>
                    </>
                  )}
                </Carousel>
                <div className="labelDiv">
                  {details?.name_lang?.[langCode]
                    ? details?.name_lang?.[langCode]
                    : details?.name}
                </div>
              </div>
              <div style={{ padding: "8px" }} className="bg-grey">
                <div className="container">
                  <div ref={sectionRef}></div>
                  <Carousel
                    swipeable={true}
                    draggable={false}
                    showDots={true}
                    responsive={responsiveimg}
                    ssr={true} // means to render carousel on server-side.
                    infinite={false}
                    autoPlay={false}
                    autoPlaySpeed={1000}
                    keyBoardControl={true}
                    customTransition="all 0.2s ease 0s"
                    transitionDuration={300}
                    containerClass="carousel-container carousel-list forMbl"
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px"
                    slidesToSlide="1"
                    arrows={true}
                  >
                    {details?.img?.length > 0 ? (
                      details?.img?.map((item) => (
                        <div>
                          <img
                            alt=" "
                            src={item}
                            style={{ height: "280px", width: "100%" }}
                          />
                        </div>
                      ))
                    ) : (
                      <>
                        <div>
                          <img alt=" " src="./assets/icon/Districtbg.jpg" />
                        </div>
                      </>
                    )}
                  </Carousel>
                  <div style={{ padding: "10px" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <h5
                        className="placeName"
                        style={{ fontSize: "15px", fontWeight: "bold" }}
                      >
                        {details?.name_lang?.[langCode]
                          ? details?.name_lang?.[langCode]
                          : details?.name}
                      </h5>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <img
                          alt=""
                          src={volume}
                          style={{ width: "17px", height: "17px" }}
                          onClick={speak}
                        />
                        <img
                          alt=""
                          src={mute}
                          style={{ width: "17px", height: "17px" }}
                          onClick={stop}
                        />
                      </div>
                    </div>
                    <DisplayContent
                      content={
                        details?.eventdetails_lang?.[langCode]
                          ? details?.eventdetails_lang?.[langCode]
                          : details?.eventdetails
                      }
                    />
                    {(details?.timing_mon_fri_from !== "" &&
                      details?.timing_mon_fri_to !== "") ||
                      (details?.timing_sat_sun_from !== "" &&
                        details?.timing_sat_sun_to !== "" && <h5>Timing</h5>)}

                    {details?.timing_mon_fri_from !== "" &&
                      details?.timing_mon_fri_to !== "" && (
                        <>
                          <p>
                            Monday-Friday :{" "}
                            {`${details?.timing_mon_fri_from}-${details?.timing_mon_fri_to}`}
                          </p>
                        </>
                      )}
                    {details?.timing_sat_sun_from !== "" &&
                      details?.timing_sat_sun_to !== "" && (
                        <>
                          <p>
                            Saturday-Sunday :{" "}
                            {`${details?.timing_sat_sun_from}-${details?.timing_sat_sun_to}`}
                          </p>
                        </>
                      )}
                    {details?.ticketAdult !== "" ||
                      (details?.ticketChild !== "" && <h5>Ticket price</h5>)}
                    {details?.ticketAdult !== "" && (
                      <p>Adult : Rs {details?.ticketAdult}/Person</p>
                    )}
                    {details?.ticketChild !== "" && (
                      <p>Children : Rs {details?.ticketChild}/Child</p>
                    )}
                    {details?.bestRoute && (
                      <>
                        {" "}
                        <h5>Best Route</h5>
                        <p>{details?.bestRoute}</p>
                      </>
                    )}
                    {details?.timeToVisit && (
                      <>
                        <h5>Best time to visit</h5>
                        <p>{details?.timeToVisit}</p>
                      </>
                    )}
                  </div>
                  <div>
                    {netsts === "true" && (
                      <>
                        <IonItem
                          style={{ width: "100%" }}
                          lines="none"
                          className="ion-align-items-center"
                        >
                          <IonLabel>
                            {getLangLabel(langCode)?.alreadyVisited}
                          </IonLabel>
                          <IonButton
                            style={{ float: "right" }}
                            expand="block"
                            onClick={() => {
                              setIsOpen(true);
                            }}
                          >
                            {getLangLabel(langCode)?.review}
                          </IonButton>
                        </IonItem>
                        <h2 style={{ paddingLeft: "8px" }}>
                          {getLangLabel(langCode)?.reviewLabel}
                        </h2>
                        {reviews.length === 0 ? (
                          <h4 style={{ paddingLeft: "8px" }}>
                            {getLangLabel(langCode)?.notReviews}
                          </h4>
                        ) : (
                          reviews.map((item, index) => (
                            <div
                              key={index}
                              style={{
                                width: "100%",
                                display: "block",
                                background: "white",
                                margin: "5px",
                                borderRadius: "8px",
                                padding: "10px",
                              }}
                            >
                              <h4>{item?.name}</h4>
                              <h5>{item?.review}</h5>
                              {item.img && item.img.length > 0 && (
                                <div
                                  style={{
                                    display: "grid",
                                    gridTemplateColumns:
                                      "repeat(auto-fit, minmax(100px, 1fr))",
                                    gap: "10px",
                                  }}
                                >
                                  {item.img.map((imageUrl, imageIndex) => (
                                    <img
                                      key={imageIndex}
                                      alt=""
                                      src={imageUrl}
                                      style={{
                                        width: "200",
                                        height: "200",
                                        marginBottom: "10px",
                                      }}
                                    />
                                  ))}
                                </div>
                              )}
                            </div>
                          ))
                        )}
                      </>
                    )}
                  </div>
                </div>

                <IonModal
                  isOpen={isOpen}
                  onDidDismiss={() => {
                    setIsOpen(false);
                    setReviewTxt("");
                  }}
                >
                  <IonHeader>
                    <IonToolbar>
                      <IonTitle>
                        {getLangLabel(langCode)?.review}{" "}
                        {details?.name_lang?.[langCode]
                          ? details?.name_lang?.[langCode]
                          : details?.name}
                      </IonTitle>
                      <IonButtons slot="end">
                        <IonButton onClick={() => handleCancel()}>
                          {getLangLabel(langCode)?.cancelBtn}
                        </IonButton>
                      </IonButtons>
                    </IonToolbar>
                  </IonHeader>
                  <IonContent className="ion-padding">
                    <IonItem>
                      <IonLabel>
                        {getLangLabel(langCode)?.enterReview} -
                      </IonLabel>
                      <IonTextarea
                        placeholder={getLangLabel(langCode)?.reviewHere}
                        onIonChange={(e) => setReviewTxt(e.target.value)}
                      ></IonTextarea>
                    </IonItem>
                    <IonItem>
                      <div
                        style={{
                          overflowY: "auto",
                          width: "100%",
                          maxHeight: "100vh",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "10px",
                            overflowY: "auto",
                          }}
                        >
                          <label
                            htmlFor="uploadInput"
                            style={{
                              cursor: "pointer",
                              marginBottom: "10px",
                              padding: "10px",
                              borderRadius: "8px",
                              background: "#007bff",
                              color: "white",
                            }}
                          >
                            {getLangLabel(langCode)?.addPhotos}
                            <input
                              id="uploadInput"
                              type="file"
                              accept="image/*"
                              multiple
                              onChange={handleImageChange}
                              style={{ display: "none" }}
                            />
                          </label>
                          <div
                            style={{
                              display: "grid",
                              gridTemplateColumns:
                                "repeat(auto-fit, minmax(100px, 1fr))",
                              gap: "10px",
                              justifyContent: "center",
                            }}
                          >
                            {previewUrls.map((url, index) => (
                              <div
                                key={index}
                                style={{
                                  position: "relative",
                                  borderRadius: "8px",
                                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                }}
                              >
                                <IonImg
                                  src={url}
                                  style={{
                                    maxWidth: "100%",
                                    height: "auto",
                                    borderRadius: "8px",
                                  }}
                                />

                                <img
                                  onClick={() => handleRemoveImage(index)}
                                  style={{
                                    position: "absolute",
                                    top: "5px",
                                    right: "5px",
                                    zIndex: "1",
                                    backgroundColor: "red",
                                    borderRadius: "50%",
                                    padding: "5px",
                                    width: "30px",
                                    height: "30px",
                                    fontSize: "14px",
                                    color: "white",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                  alt=""
                                  src={remove}
                                  height={25}
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </IonItem>

                    <IonButton expand="block" onClick={() => ReviewFn()}>
                      {getLangLabel(langCode)?.submit}
                    </IonButton>
                    <p style={{ fontSize: "11px" }}>
                      {getLangLabel(langCode)?.reviewFooter}
                    </p>
                  </IonContent>
                </IonModal>
                <div className="container">
                  {netsts === "true" && (
                    <>
                      <IonItem style={{ width: "100%" }} lines="none">
                        <IonLabel style={{ padding: "10px" }}>
                          {getLangLabel(langCode)?.nearPlaces}
                        </IonLabel>
                      </IonItem>
                      <div className="">
                        <div className="img-card">
                          <div className="padding-box">
                            <div className="gridCard">
                              {nearbyPlaces.length === 0 ? (
                                <h4 style={{ paddingLeft: "8px" }}>
                                  {getLangLabel(langCode)?.noNearPlaces}
                                </h4>
                              ) : (
                                nearbyPlaces.map((place, index) => (
                                  <div key={index} className="cardItem">
                                    <div
                                      className="caros-card"
                                      style={{
                                        paddingBottom: "10px",
                                        marginBottom: "10px",
                                      }}
                                      onClick={() => goToNearbyPlace(place)}
                                    >
                                      <img alt=" " src={place?.img?.[0]} />
                                      <h5>
                                        {place?.name_lang?.[langCode]
                                          ? place?.name_lang?.[langCode]
                                          : place?.name}
                                      </h5>
                                      <div>
                                        <h6
                                          style={{
                                            color: "black",
                                            textAlign: "left",
                                            padding: "0 14px",
                                            display: "flex",
                                            alignItems: "flex-start",
                                          }}
                                        >
                                          <LocationOnIcon />{" "}
                                          {langCode === "en"
                                            ? `${place.city}, ${place.state}`
                                            : `${getCityInLang(
                                                place.city,
                                                langCode
                                              )}, ${
                                                getLangState()?.[place.state]
                                              }`}
                                        </h6>
                                      </div>
                                    </div>
                                  </div>
                                ))
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="container">
              <div className="ion-padding custom-skeleton">
                <IonSkeletonText animated style={{ width: "60%" }} />
                <IonSkeletonText animated />
                <IonSkeletonText animated style={{ width: "88%" }} />
                <IonSkeletonText animated style={{ width: "70%" }} />
                <IonSkeletonText animated style={{ width: "60%" }} />
              </div>
              <IonList>
                <IonListHeader>
                  <IonLabel>
                    <IonSkeletonText animated style={{ width: "20%" }} />
                  </IonLabel>
                </IonListHeader>
                <IonItem>
                  <IonAvatar slot="start">
                    <IonSkeletonText animated />
                  </IonAvatar>
                  <IonLabel>
                    <h3>
                      <IonSkeletonText animated style={{ width: "50%" }} />
                    </h3>
                    <p>
                      <IonSkeletonText animated style={{ width: "80%" }} />
                    </p>
                    <p>
                      <IonSkeletonText animated style={{ width: "60%" }} />
                    </p>
                  </IonLabel>
                </IonItem>
                <IonItem>
                  <IonThumbnail slot="start">
                    <IonSkeletonText animated />
                  </IonThumbnail>
                  <IonLabel>
                    <h3>
                      <IonSkeletonText animated style={{ width: "50%" }} />
                    </h3>
                    <p>
                      <IonSkeletonText animated style={{ width: "80%" }} />
                    </p>
                    <p>
                      <IonSkeletonText animated style={{ width: "60%" }} />
                    </p>
                  </IonLabel>
                </IonItem>
                <IonItem>
                  <IonSkeletonText
                    animated
                    style={{ width: "27px", height: "27px" }}
                    slot="start"
                  />
                  <IonLabel>
                    <h3>
                      <IonSkeletonText animated style={{ width: "50%" }} />
                    </h3>
                    <p>
                      <IonSkeletonText animated style={{ width: "80%" }} />
                    </p>
                    <p>
                      <IonSkeletonText animated style={{ width: "60%" }} />
                    </p>
                  </IonLabel>
                </IonItem>
              </IonList>
            </div>
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

export default withRouter(ParticularPlaceInformation);
