import { useAuthValue } from './AuthContext'
import React from "react";
import {
    IonPage,
    IonHeader,
    IonContent,
    IonToolbar,
    IonButtons,
    IonMenuButton,
    IonSearchbar,
} from "@ionic/react";
import { withRouter } from "react-router";

import { useState, useEffect } from 'react'

import { auth } from './index'

import { sendEmailVerification } from 'firebase/auth'

import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';




const Verify = ({ history }) => {
    const { currentUser } = useAuthValue()
    const [buttonDisabled, setButtonDisabled] = useState(false)
    const [time, setTime] = useState(60)
    const [timeActive, setTimeActive] = useState(false)

    const resendEmailVerification = () => {
        setButtonDisabled(true)
        sendEmailVerification(auth.currentUser)
            .then(() => {
                setButtonDisabled(false)
                setTimeActive(true)
            }).catch((err) => {
                alert(err.message)
                setButtonDisabled(false)
            })
    }

    useEffect(() => {
        let interval = null
        if (timeActive && time !== 0) {
            interval = setInterval(() => {
                setTime((time) => time - 1)
            }, 1000)
        } else if (time === 0) {
            setTimeActive(false)
            setTime(60)
            clearInterval(interval)
        }
        return () => clearInterval(interval);
    }, [timeActive, time])

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 5,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 5,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 5,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="t-bar">
                    <div className="top-nav">
                        <div className="nav_inner">
                            {/* <ArrowBackOutlinedIcon className="color-white" onClick={()=>history.push('./')} /> */}
                            <h4 className="title-txt">Verify Email</h4>
                            <div className="d-flex">
                                {/* <NotificationsOutlinedIcon className="color-white" /> */}
                                <IonButtons slot="end">
                                    <IonMenuButton className="color-white" />
                                </IonButtons>
                            </div>
                        </div>
                    </div>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <span>{currentUser?.email}</span>

                {/* <button  onClick={resendEmailVerification}
                disabled={buttonDisabled}>Resend Email</button> */}

                <button
                    onClick={resendEmailVerification}
                    // disabled={buttonDisabled}
                    disabled={timeActive}
                >Resend Email {timeActive && time}</button>

            </IonContent>
        </IonPage>
    );
};

export default withRouter(Verify);