import React, { useState, useEffect } from "react";
import { IonPage, IonHeader, IonContent, IonToolbar } from "@ionic/react";
import Paper from "@mui/material/Paper";
import { withRouter } from "react-router";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import trash from "../../images/can.png";
import { FirebaseAnalytics } from "@capacitor-firebase/analytics";
import DesktopHeader from "../../components/DesktopHeader";
import logo from "../../images/logo.png";
import { getLangLabel } from "../../utility/languageTranslator";

const Downloads = ({ history }) => {
  const [downloadcityList, setDownloadCityList] = useState([]);
  const [routeName, setRouteName] = useState("");
  const [langCode, setlangCode] = useState("en");

  useEffect(() => {
    const languageCode = localStorage.getItem("languageCode");
    setlangCode(languageCode || "en");
    const fetchData = async () => {
      const dataArr = JSON.parse(window.localStorage.getItem("myDownloads"));
      console.log("Data from localStorage:", dataArr);
      if (dataArr) {
        setDownloadCityList(dataArr);
      } else {
        console.log("No data found in localStorage for 'myDownloads'");
      }
    };

    fetchData();
  }, []);

  const handleDelete = async (index, item) => {
    const updatedList = [...downloadcityList];
    updatedList.splice(index, 1); // Remove one item at the specified index
    setDownloadCityList(updatedList);
    // Update localStorage if necessary
    await window.localStorage.setItem(
      "myDownloads",
      JSON.stringify(updatedList)
    );
    const staredValues = JSON.parse(localStorage.getItem("staredValues")) || [];
    const newStaredValues = staredValues?.filter(
      (title) => title !== `${item?.state}_${item?.district}`
    );
    localStorage.setItem("staredValues", JSON.stringify(newStaredValues));
    alert(getLangLabel(langCode)?.dataDeleted);
  };
  const setAnalyticsScreen = async () => {
    await FirebaseAnalytics.logEvent({
      name: "screen_view",
      params: {
        screen_name: routeName,
        screen_class: "Bookmarks",
      },
    });
  };
  useEffect(() => {
    const pathname = history.location.pathname;
    const trimmedPathname = pathname.slice(1);
    console.log("routeName===", trimmedPathname);
    setRouteName(trimmedPathname);
    if (trimmedPathname !== "") {
      setAnalyticsScreen();
    }
  }, [routeName]);

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <IonPage>
      <IonHeader style={{ background: "#fff" }}>
        <IonToolbar className="t-bar">
          <div className="top-nav">
            <div className="nav_inner">
              <ArrowBackOutlinedIcon
                className="color-white"
                onClick={handleGoBack}
              />
              <h4 className="title-txt">{getLangLabel(langCode)?.downloads}</h4>
              <img
                onClick={() => history.push("/home")}
                src={logo}
                width={50}
                height={40}
                alt=""
              />
            </div>
          </div>
        </IonToolbar>
        <DesktopHeader />
      </IonHeader>
      <IonContent>
        <div className="flex-head">
          <div className="container">
            <h4 className="inner-title">{getLangLabel(langCode)?.downloads}</h4>
          </div>
        </div>
        <div className="city-list-box">
          <div className="container">
            {downloadcityList.length === 0 ? (
              <h4 style={{ textAlign: "center" }}>
                {getLangLabel(langCode)?.notDownloads}
              </h4>
            ) : (
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{ fontWeight: "700", fontSize: "15px" }}
                      >
                        Id
                      </TableCell>
                      <TableCell
                        style={{ fontWeight: "700", fontSize: "15px" }}
                      >
                        District
                      </TableCell>
                      <TableCell
                        style={{ fontWeight: "700", fontSize: "15px" }}
                      ></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {downloadcityList.map((item, index) => (
                      <TableRow
                        key={item.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {index + 1}
                        </TableCell>
                        <TableCell
                          onClick={async () => {
                            let details = await JSON.parse(
                              localStorage.getItem("details")
                            );
                            let updated_details = {
                              ...details,
                              state: item.state,
                              city: item.district,
                            };
                            await localStorage.setItem(
                              "details",
                              JSON.stringify(updated_details)
                            );
                            history.push(`/placeinformation`);
                          }}
                        >
                          {item.district} ({item.state})
                        </TableCell>
                        <TableCell>
                          <img
                            src={trash}
                            style={{ width: "30px" }}
                            onClick={() => handleDelete(index, item)}
                            alt=""
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </div>

        </div>
      </IonContent>
      <div style={{textAlign: "center", background: "#f3f3f3"}}>
        <p>Bookmarked pages are also available for offline use</p>
      </div>
    </IonPage>
  );
};

export default withRouter(Downloads);
