import React, { useState, useEffect } from "react";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonContent,
  useIonViewDidLeave,
} from "@ionic/react";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { useHistory, useParams } from "react-router-dom";
import volume from "../../images/volume.png";
import mute from "../../images/mute.png";
import { APIFY, HTTP_CLIENT } from "../../services/Api";
import { FirebaseAnalytics } from "@capacitor-firebase/analytics";
import { TextToSpeech } from "@capacitor-community/text-to-speech";
import DesktopHeader from "../../components/DesktopHeader";
import logo from "../../images/logo.png";
import { removeTagsAndStyles } from "../../utility/commonMethods";

const BlogDetails = () => {
  const history = useHistory();
  const { _id, pathId } = useParams();
  const [blog, setBlog] = useState(null);
  const [langCode, setlangCode] = useState("en");

  useEffect(() => {
    const languageCode = localStorage.getItem("languageCode");
    setlangCode(languageCode || "en");
    const getBlogDetails = async () => {
      try {
        let res;
        if (_id) {
          res = await HTTP_CLIENT(APIFY(`/blogs/${_id}`), "GET");
        } else if (pathId) {
          res = await HTTP_CLIENT(APIFY(`/blogs/${pathId}`), "GET");
        }

        setBlog(res.data);
      } catch (err) {
        console.log("error", err);
      }
    };

    if (_id || pathId) {
      getBlogDetails();
    }
  }, [_id, pathId]);
  const speak = async () => {
    try {
      await TextToSpeech.speak({
        text: removeTagsAndStyles(blog?.description) || "",
        lang: "en-US",
        rate: 1.0,
        pitch: 1.0,
        volume: 1.0,
        category: "ambient",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const stop = async () => {
    await TextToSpeech.stop();
  };
  const setAnalyticsScreen = async () => {
    try {
      await FirebaseAnalytics.setCurrentScreen({
        screenName: `Blog Details: ${blog?.title}`,
        screenClass: "Blog Details",
      });
      await FirebaseAnalytics.logEvent({
        name: "screen_view",
        params: {
          screen_name: `Blog Details: ${blog?.title}`,
          screen_class: "Blog Details",
        },
      });
      console.log("Screen view event logged");
    } catch (error) {
      console.error("Error logging screen view event:", error);
    }
  };
  useEffect(() => {
    if (blog?.title) setAnalyticsScreen();
  }, [blog?.title]);
  useIonViewDidLeave(() => {
    TextToSpeech.stop();
  });
  return (
    <IonPage>
      <IonHeader style={{ background: "#fff" }}>
        <IonToolbar className="t-bar">
          <div className="top-nav">
            <div className="nav_inner">
              <ArrowBackOutlinedIcon
                className="color-white"
                onClick={() => history.push("/blogs")}
              />
              <h4 className="title-txt">Blog Detail</h4>

              <img
                onClick={() => history.push("/home")}
                src={logo}
                width={50}
                height={40}
                alt=""
              />
            </div>
          </div>
        </IonToolbar>
        <DesktopHeader />
      </IonHeader>
      <IonContent>
        <div className="flex-head">
          <div className="container">
            <h4 className="inner-title">
              <strong>Blog</strong>
              {blog?.title_lang?.[langCode]
                ? blog?.title_lang?.[langCode]
                : blog?.title}
            </h4>
          </div>
        </div>
        {blog && (
          <div className="container">
            <div className="blog-details">
              <img
                src={blog.img}
                alt={blog.title}
                style={{
                  height: "230px",
                  display: "block",
                  margin: "auto",
                  paddingTop: "10px",
                }}
              />
              <div className="details-content">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "0 15px",
                  }}
                >
                  <h5
                    className="placeName"
                    style={{ fontSize: "15px", fontWeight: "bold" }}
                  >
                    {blog?.title_lang?.[langCode]
                      ? blog?.title_lang?.[langCode]
                      : blog?.title}
                  </h5>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <img
                      alt=""
                      src={volume}
                      style={{ width: "17px", height: "17px" }}
                      onClick={speak}
                    />
                    <img
                      alt=""
                      src={mute}
                      style={{ width: "17px", height: "17px" }}
                      onClick={stop}
                    />
                  </div>
                </div>
                <div
                  style={{ textAlign: "left", padding: "0px 15px" }}
                  dangerouslySetInnerHTML={{
                    __html: blog?.description_lang?.[langCode]
                      ? blog?.description_lang?.[langCode]
                      : blog?.description,
                  }}
                />
                {/* Display description without HTML tags */}
              </div>
            </div>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default BlogDetails;
